import invariant from 'invariant'
import { withProps } from 'recompose'
import { difference, isEmpty, keys } from 'lodash'

const configurableComponent = (defaultConfig: {}) => {
  const config = defaultConfig
  const configure = (params: {}) => {
    const badParams = difference(keys(params), keys(defaultConfig))
    invariant(
      isEmpty(badParams),
      `Bad config param(s) passed: [ ${badParams.join(', ')} ]. Only [ ${keys(
        defaultConfig
      ).join(', ')} ] allowed.`
    )

    Object.assign(config, params)
  }

  const withConfig = withProps((props: {}) => Object.assign({}, config, props))

  return [withConfig, configure]
}

export default configurableComponent
