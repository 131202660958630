import React from 'react'
import cn from 'classnames'
import { MenuItem, NavDropdown } from 'react-bootstrap'
import { uniqueId, noop } from 'lodash'
import { withStateHandlers } from 'recompose'

import Link from 'packages/components/common/Link'

const buildMenuItem = ({ name, link }, eventKey) => (
  <MenuItem
    componentClass={Link}
    href={link}
    key={eventKey}
    eventKey={eventKey}
  >
    {name}
  </MenuItem>
)

const DropdownItem = ({
  item: { name, menu },
  eventKey,
  handleOpen,
  handleClose,
  open,
}) => (
  <>
    <NavDropdown
      title={name}
      className={cn('hidden-xs', 'hidden-sm')}
      id={uniqueId('nav-dropdown-')}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      open={open}
      onToggle={noop}
    >
      {menu.map((element, idx) => buildMenuItem(element, `${eventKey}.${idx}`))}
    </NavDropdown>
    <NavDropdown
      title={name}
      className={cn('hidden-md', 'hidden-lg')}
      id={uniqueId('nav-dropdown-')}
    >
      {menu.map((element, idx) => buildMenuItem(element, `${eventKey}.${idx}`))}
    </NavDropdown>
  </>
)

export default withStateHandlers(
  {
    open: false,
  },
  {
    handleOpen: () => () => ({ open: true }),
    handleClose: () => () => ({ open: false }),
  }
)(DropdownItem)
