import { Map, Record, RecordOf } from 'immutable'

import { makeMap } from './CollectionFactory'
import { Good, GoodProps } from './Good'
import { Vendor, VendorProps } from './Vendor'

export type OfferProps = {
  itemKey: string
  name?: string
  price: number
  amount: number
  cartAmount: number
  amountString: string
  minAmount: number
  multiplicityAmount: number
  deliveryTime: any
  isStock: boolean
  isReturnAllowed: boolean
  returnPercent: number
  vendor?: VendorProps | Vendor
  good: GoodProps | Good
}

const defaultValues: OfferProps = {
  itemKey: '',
  name: undefined,
  price: 0,
  amount: 0,
  amountString: '',
  cartAmount: 0,
  minAmount: 1,
  multiplicityAmount: 1,
  deliveryTime: null,
  isStock: false,
  isReturnAllowed: true,
  returnPercent: 0,
  vendor: undefined,
  good: Good(),
}

export type Offer = RecordOf<OfferProps>
export type OffersCollection = Map<string, Offer>

const mapper = <T>({ good, ...props }: OfferProps): OfferProps => {
  return {
    ...props,
    good: Record.isRecord(good) ? good : Good(good as GoodProps),
  }
}

const makeOffer: Record.Factory<OfferProps> = Record(
  defaultValues,
  'OfferRecord'
)

export const Offer = (props?: OfferProps): Offer =>
  makeOffer(props ? mapper(props) : undefined)

export const OffersCollection = makeMap(Offer, 'itemKey')
