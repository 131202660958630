import AboutRoutes from './about'
import BalanceRoutes from './balance'
import BlogRoutes from './blog'
import CabinetRoutes from './cabinet'
import CartRoutes from './cart'
import ContactsRoutes from './contacts'
import GoodsCatalogRoutes from './goods-catalog'
import GarageRoutes from './garage'
import HomeRoutes from './home'
import OrdersRoutes from './orders'
import LoginRoutes from './login'
import SearchRoutes from './search'
import StaticRoutes from './static'
import ErrorsRoutes from './errors'
// import IlcatsCatalogRoutes from './ilcats-catalog'
import ProfileRoutes from './profile'
import RegistrationRoutes from './registration'
// import ServiceRoutes from './service'

export default store => [
  AboutRoutes(),
  BalanceRoutes(),
  ...BlogRoutes(),
  ...CabinetRoutes(),
  CartRoutes(),
  ContactsRoutes(),
  GoodsCatalogRoutes(),
  GarageRoutes(),
  HomeRoutes(),
  OrdersRoutes(),
  LoginRoutes(),
  SearchRoutes(),
  // IlcatsCatalogRoutes(),
  ProfileRoutes(),
  // ServiceRoutes(),
  ...RegistrationRoutes(),
  ...StaticRoutes(),
  ...ErrorsRoutes(),
]
