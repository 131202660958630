import { List, Record, RecordOf } from 'immutable'
import { castArray, get, isEmpty } from 'lodash'
import qs from 'qs'

import getImagineUrl from 'packages/core/helpers/getImagineUrl'
import { Brand, BrandProps } from './Brand'
import { OfferProps, OffersCollection } from './Offer'
import { makeList } from './CollectionFactory'

export type GoodProps = {
  id: string
  brandName?: string
  brand: BrandProps | Brand
  number: string
  name?: string
  description?: string
  catalogLink?: string
  searchLink?: string
  images?: Array<string>
  thumbs?: Array<string>
  offers?: Array<OfferProps> | OffersCollection
}

export type Good = RecordOf<GoodProps>
export type GoodsCollection = List<Good>

const defaultValues: GoodProps = {
  id: '',
  brandName: undefined,
  brand: Brand(),
  number: '',
  name: undefined,
  description: undefined,
  catalogLink: undefined,
  searchLink: undefined,
  images: undefined,
  thumbs: undefined,
  offers: undefined,
}

const makeGood: Record.Factory<GoodProps> = Record(defaultValues, 'GoodRecord')

const createCatalogLink = (
  brandName: string,
  number: string
): string | undefined =>
  isEmpty(brandName) || isEmpty(number)
    ? undefined
    : `/goods/${brandName}/${number}`

const createSearchLink = (
  brandName: string,
  number: string
): string | undefined =>
  isEmpty(brandName) || isEmpty(number)
    ? undefined
    : `/search${qs.stringify({ brandName, number }, { addQueryPrefix: true })}`

const mapper = ({
  brand,
  description,
  name,
  number,
  images,
  thumbs,
  ...props
}: GoodProps): GoodProps => ({
  ...props,
  brand: Record.isRecord(brand) ? brand : Brand(brand as BrandProps),
  brandName: brand.name,
  name: `${get(brand, 'name')} ${number}`,
  number,
  description: name,
  catalogLink: createCatalogLink(brand.name, number),
  searchLink: createSearchLink(brand.name, number),
  images: isEmpty(images) ? undefined : castArray(images),
  thumbs: isEmpty(thumbs) ? undefined : castArray(thumbs),
})

export const Good = (props?: GoodProps): Good =>
  makeGood(props ? mapper(props) : undefined)

export const GoodsCollection = makeList(Good)
