import React from 'react'
import { isFunction } from 'lodash'

import { ToggleState, ToggleHandler } from '.'

export interface SpreadingExampleProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  onClick(): Function
}

export interface ModalWindow {
  button?: (props: any) => React.ReactElement | React.ReactElement
  // children: React.ReactElement
  children: React.ComponentType<ToggleState & ToggleHandler>
}

const ModalWindow: React.StatelessComponent<ToggleState & ToggleHandler> = ({
  show,
  button,
  children,
  handleHide,
  handleShow,
}) => (
  <>
    {button &&
      (React.isValidElement(button)
        ? React.cloneElement(button as React.ReactElement, {
            onClick: handleShow,
          })
        : isFunction(button) && button({ handleShow }))}
    {React.isValidElement(children)
      ? React.cloneElement(children, { onHide: handleHide, handleHide, show })
      : isFunction(children) && children({ handleHide, handleShow, show })}
  </>
)

export default ModalWindow
