type ilkatsProps = {
  clientId?: number
}

const settings: ilkatsProps = {}

try {
  Object.assign(settings, JSON.parse(process.env.REACT_APP_ILCATS || '[]'))
} catch (error) {}

export default settings
