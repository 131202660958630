import React from 'react'
import { Link } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import FontAwesome from 'react-fontawesome'
import { Col, Row } from 'react-bootstrap'

import Section from 'app/components/PageSection'
import Layout from 'app/view/Layout'

const ErrorTemplate = ({ code, error, description }) => {
  const lastLocation = useLastLocation()

  return (
    <Layout>
      <Section
        title="Упс, что-то пошло не так..."
        icon="exclamation-circle"
        bottomSpaceLarge
      >
        <Row>
          <Col xs={4}>
            <p
              className="text-danger text-center"
              style={{ fontSize: '50px', minWidth: 125 }}
            >
              <FontAwesome name="exclamation-triangle" />
              &nbsp;
              {code}
            </p>
          </Col>
          <Col xs={8}>
            <h1>{error}</h1>
            <p>{description}</p>
            <ul className="arrow-list">
              {lastLocation && (
                <li>
                  <Link to={lastLocation}>На предыдущую страницу</Link>
                </li>
              )}
              <li>
                <Link to="/">На главную сраницу</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Section>
    </Layout>
  )
}

export default ErrorTemplate
