import { createElement } from 'react'

import Layout, { LayoutProps } from './Layout'
export * from './Layout'

export const withLayout = (layoutProps?: Partial<LayoutProps>) => <T>(
  Page: React.ComponentType<T>
) => (props: T) => {
  return createElement(Layout, layoutProps, createElement(Page, props))
}

export const makeLayout = (AppLayout = Layout) => (
  layoutProps?: Partial<LayoutProps>
) => <T>(Page: React.ComponentType<T>) => (props: T) => {
  return createElement(AppLayout, layoutProps, createElement(Page, props))
}
