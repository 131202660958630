import { memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, lifecycle, mapProps, withContext } from 'recompose'
import { omit, pick } from 'lodash'

import './initializers'

import withAutoScrollToTop from 'packages/core/hoc/withAutoScrollToTop'
import { initialize } from 'packages/core/features/auth/actions'
import { addToCart } from 'packages/core/features/cart/actions'
import {
  login,
  logout,
  resetPasswordRequest,
} from 'packages/core/features/user/actions'
import {
  fetchCarouselAction,
  carouselSelector,
} from 'packages/core/features/Carousel'
import { fetchOffices } from 'packages/core/features/office/actions'
import App from 'app/app'

import {
  orderContractsSelector,
  currentContractSelector,
} from 'packages/core/features/contract/selectors'
import { userSelector } from 'packages/core/features/user/selectors'
import { cartSelector } from 'packages/core/features/cart/selectors'
import { companySelector } from 'packages/core/features/company/selectors'

const mapStateToProps = createStructuredSelector({
  cart: cartSelector,
  contracts: orderContractsSelector,
  currentContract: currentContractSelector,
  user: userSelector,
  carousel: carouselSelector,
  company: companySelector,
  // getCarousel: carouselSelector,
})
const mapDispatchToProps = {
  initialize,
  addToCart,
  login,
  logout,
  resetPasswordRequest,
  fetchCarousel: fetchCarouselAction,
  fetchOffices,
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withLoginPromify = mapProps(({ login, ...props }) => ({
  ...props,
  login: credentials =>
    new Promise((onSuccess, onFailure) =>
      login(credentials, {
        onSuccess,
        onFailure,
      })
    ),
}))

const appContext = {
  cart: PropTypes.object,
  contracts: PropTypes.object,
  currentContract: PropTypes.object,
  user: PropTypes.object,
  addToCart: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  resetPasswordRequest: PropTypes.func.isRequired,
  // getCarousel: PropTypes.func.isRequired,
  carousel: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  fetchCarousel: PropTypes.func.isRequired,
}

const withAppContext = withContext(appContext, props =>
  pick(props, Object.keys(appContext))
)

const withLifecycle = lifecycle({
  componentDidMount() {
    this.props.initialize()
    this.props.fetchOffices()
  },
})

const withAppProps = mapProps(props => omit(props, 'initialize'))

export default compose(
  withConnect,
  withLoginPromify,
  withAppContext,
  withLifecycle,
  withAppProps,
  withAutoScrollToTop,
  memo
)(App)
