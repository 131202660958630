import { List, Map, RecordOf } from 'immutable'
import { get, isFunction } from 'lodash'

export const makeList = <T>(factory: (props: T) => RecordOf<T>) => (
  items: Array<T> = [],
  itemProps?: Partial<T>
): List<RecordOf<T>> =>
  List(items.map((item: T) => factory(Object.assign({}, item, itemProps))))

export const makeMap = <T>(
  factory: (props: T) => RecordOf<T>,
  idProperty: keyof T | ((item: T) => string)
) => (
  items: Array<T> = [],
  itemProps?: Partial<T>
): Map<typeof idProperty, RecordOf<T>> =>
  Map(
    items.map((item: any, index: number) => [
      isFunction(idProperty)
        ? idProperty(item)
        : get(item, idProperty || 'id', index),
      factory(Object.assign({}, item, itemProps)),
    ])
  )
