import { compose, withHandlers, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { omit } from 'lodash'

import { authenticate, logout } from '../auth/actions'
import { userSelector } from './selectors'
import { fetchUser } from './actions'

// const userSelector = makeSelectUser()
const mapPropsToState = createStructuredSelector({
  user: userSelector,
})
const mapDispatchToProps = {
  authenticate,
  logout,
  fetchUser,
}
const withConnect = connect(mapPropsToState, mapDispatchToProps)

const withUserHandlers = compose(
  withHandlers({
    getUser: ({ user }) => () => user,
  }),
  withHandlers({
    login: ({ authenticate, fetchUser, getUser }) => async credientals => {
      try {
        await authenticate(credientals)
        await fetchUser()

        return getUser()
      } catch (error) {
        throw error
      }
    },
  })
)

const withUserProps = mapProps(props =>
  omit(props, 'dispatch', 'getUser', 'fetchUser', 'authenticate')
)

export default compose(withConnect, withUserHandlers, withUserProps)
