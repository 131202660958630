import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
// import { memoize } from 'lodash'
import { Map } from 'immutable'

import { FETCH_CAROUSEL } from './constants'

const selectState = getQuery({ type: FETCH_CAROUSEL })

// export const carouselSelector = createSelector(
//   selectState,
//   ({ data, ...state }) =>
//     memoize((name: string) =>
//       (data || Map()).has(name) ? (data || Map()).get(name).toJS() : undefined
//     )
// )

export const carouselSelector = createSelector(selectState, ({ data }) =>
  (data || Map()).toJS()
)
