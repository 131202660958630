import qs from 'qs'

export const getSearchUrl = good => {
  const {
    brand: { name: brandName },
    number,
  } = good
  return `/search?${qs.stringify({ brandName, number })}`
}

export const getCatalogUrl = good => {
  const {
    brand: { name: brandName },
    number,
  } = good
  return `/goods/${brandName}/${number}`
}

const getUrl = (good, type = 'search') => {
  switch (type) {
    case 'search':
      return getSearchUrl(good)
    case 'catalog':
      return getCatalogUrl(good)
    default:
      return getSearchUrl(good)
  }
}

export default getUrl
