// import moment from 'moment'
import { Record, RecordOf } from 'immutable'
// import { chain /*get, isEmpty*/ } from 'lodash'

// import { DayProps } from './Day'

type BusinessHoursProps = {
  short?: any
  full?: any
}

export type BusinessHours = RecordOf<BusinessHoursProps>

const defaultValues: BusinessHoursProps = {
  short: undefined,
  full: undefined,
}

type weekDayName =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday'

type weekProps = {
  [key in weekDayName]: {
    start: number
    end: number
  }
}

// const businessHours: weekProps = {
//   Monday: {
//     start: 9,
//     end: 19,
//   },
//   Tuesday: {
//     start: 9,
//     end: 19,
//   },
//   Wednesday: {
//     start: 9,
//     end: 19,
//   },
//   Thursday: {
//     start: 9,
//     end: 19,
//   },
//   Friday: {
//     start: 9,
//     end: 19,
//   },
//   Saturday: {
//     start: 10,
//     end: 17,
//   },
//   Sunday: {
//     start: 10,
//     end: 17,
//   },
// }

// const groupDays = ({
//   order,
//   days
// }, options = {}) => {
//   const {
//     timeFormat = 'LT', weekdaysShort = true
//   } = options

//   const groups = order.reduce((result, day) => {
//     const prev = result.slice(-1).pop()
//     const curr = days[day]
//     const weekDay = parseWeekDay(day, weekdaysShort)

//     if (curr) {
//       const startTime = parseTime(curr.start, timeFormat)
//       const endTime = parseTime(curr.end, timeFormat)

//       if (prev && prev.startTime === startTime && prev.endTime === endTime) {
//         prev.endDay = weekDay
//       } else {
//         result.push({
//           startTime,
//           endTime,
//           startDay: weekDay
//         })
//       }
//     } else if (prev) {
//       result.push(undefined)
//     }
//     return result
//   }, [])
// const parseWeekDay = (dayName: weekDayName, weekdaysShort?: boolean) => {
//   // const localeData = moment.localeData()
//   // console.log(localeData.weekdaysParse)
//   // console.log(localeData.weekdaysParse(dayName, '', false))
//   return moment[weekdaysShort ? 'weekdaysShort' : 'weekdays'](
//     moment.localeData('en').weekdaysParse(dayName, 'day', false)
//   )
// }
// declare global {
//   interface Window {
//     moment: any
//   }
// }
// window.moment = moment
// const localeData = moment.localeData()
// console.log(localeData)
// console.log(parseWeekDay)
const mapper = (days: weekProps): BusinessHoursProps => {
  // const full = chain(days)
  //   // .mapKeys(dayName => parseWeekDay(dayName))
  //   .mapKeys((value, dayName) => parseWeekDay(dayName as weekDayName))
  //   // .concat(Array(7))
  //   // .take(7)
  //   // .map((day, idx) => ({
  //   //   day: idx + 1,
  //   //   weekDay: moment.weekdays(idx + 1),
  //   //   shortWeekDay: moment.weekdaysShort(idx + 1),
  //   //   isHoliday: isEmpty(day),
  //   //   timeFrom: get(day, 0, null),
  //   //   timeTo: get(day, 1, null),
  //   // }))
  //   .value()
  // console.log(days, full)
  // const full = chain(days)
  //   .concat(Array(7))
  //   .take(7)
  //   .map((day, idx) => ({
  //     day: idx + 1,
  //     weekDay: moment.weekdays(idx + 1),
  //     shortWeekDay: moment.weekdaysShort(idx + 1),
  //     isHoliday: isEmpty(day),
  //     timeFrom: get(day, 0, null),
  //     timeTo: get(day, 1, null),
  //   }))
  //   .value()

  return {
    short: undefined,
    full: undefined,
  }
}

const makeBusinessHours: Record.Factory<BusinessHoursProps> = Record(
  defaultValues,
  'BusinessHoursPropsRecord'
)

export const BusinessHours = (props?: weekProps): BusinessHours =>
  makeBusinessHours(props ? mapper(props) : undefined)

// import { List } from 'immutable'
// import moment from 'moment'
// import { get, isEmpty, isEqual, pick, take } from 'lodash'

// import { EntityRecord, ListRecord } from '../../utils/CollectionRecord'

// const DayRecord = EntityRecord(
//   {
//     day: null,
//     weekDay: null,
//     shortWeekDay: null,
//     timeFrom: null,
//     timeTo: null,
//     isHoliday: false,
//   },
//   ({ day, timeFrom, timeTo, ...rest }) => ({
//     day,
//     weekDay: moment.weekdays(day),
//     shortWeekDay: moment.weekdaysShort(day),
//     timeFrom,
//     timeTo,
//     isHoliday: isEmpty(timeFrom) && isEmpty(timeTo),
//   })
// )

// const DaysRangeRecord = EntityRecord({
//   weekDayFrom: null,
//   shortWeekDayFrom: null,
//   weekDayTo: null,
//   shortWeekDayTo: null,
//   timeFrom: null,
//   timeTo: null,
//   isHoliday: false,
// })

// const WeekRecord = (days = []) => {
//   const week = take(days.concat(Array(7)), 7).map((day, idx) => ({
//     day: idx + 1,
//     timeFrom: get(day, 0, null),
//     timeTo: get(day, 1, null),
//   }))
//   return ListRecord(DayRecord)(week)
// }

// const shortFormat = week => {
//   return List().withMutations(short => {
//     week.forEach(day => {
//       const idx = short.findLastIndex(dayRange =>
//         isEqual(
//           pick(dayRange, 'timeFrom', 'timeTo'),
//           pick(day, 'timeFrom', 'timeTo')
//         )
//       )
//       short.update(
//         idx < 0 ? short.count() : idx,
//         DaysRangeRecord(day.toJS()),
//         dayRange => {
//           const changes = {
//             [dayRange.shortWeekDayFrom
//               ? 'shortWeekDayTo'
//               : 'shortWeekDayFrom']: day.shortWeekDay,
//             [dayRange.weekDayFrom ? 'weekDayTo' : 'weekDayFrom']: day.weekDay,
//           }
//           return dayRange.merge(changes)
//         }
//       )
//     })
//   })
// }

// const BusinessHoursRecord = EntityRecord(
//   {
//     full: WeekRecord(),
//     short: List(),
//   },
//   ({ days }) => ({
//     full: WeekRecord(days),
//     short: shortFormat(WeekRecord(days)),
//   })
// )

// export default BusinessHoursRecord
