import { compose, withProps } from 'recompose'
import { get, keys } from 'lodash'
import invariant from 'invariant'

import forms from './forms'

// import { default as contactUs } from './contactUs'
// import { default as leaveComment } from './leaveComment'
// import { default as subscribe } from './subscribe'

// const forms = {
//   contactUs,
//   subscribe,
//   leaveComment,
// }

const withForm = (formName, props) => {
  const form = get(forms, formName)

  invariant(
    form,
    `Form [ ${formName} ] not found. Only [ ${keys(forms).join(
      ', '
    )} ] forms available.`
  )

  return compose(withProps(props), form)
}

export default withForm
