import { List, Record, RecordOf } from 'immutable'
import { isEmpty } from 'lodash'

import { makeList } from './CollectionFactory'

export type BrandProps = {
  id?: number | string
  name: string
  description?: string
  catalogLink?: string
  site?: string
  images: Array<string>
  thumbs: Array<string>
  country?: string
  priority?: string
  warranty?: string
  warrantyDescription?: string
}
export type Brand = RecordOf<BrandProps>
export type BrandsCollection = List<Brand>

const createCatalogLink = ({ name }: BrandProps): string | undefined =>
  isEmpty(name) ? undefined : `/goods/${name}`

const defaultValues: BrandProps = {
  id: undefined,
  name: '',
  description: undefined,
  catalogLink: undefined,
  site: undefined,
  images: [],
  thumbs: [],
  country: undefined,
  priority: undefined,
  warranty: undefined,
  warrantyDescription: undefined,
}

const mapper = ({ ...props }: BrandProps): BrandProps => ({
  ...props,
  catalogLink: createCatalogLink(props),
})

const makeBrand: Record.Factory<BrandProps> = Record(
  defaultValues,
  'BrandRecord'
)

export const Brand = (props?: BrandProps): Brand =>
  makeBrand(props ? mapper(props) : undefined)
export const BrandsCollection = makeList(Brand)
