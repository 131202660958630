import React from 'react'
import { Link } from 'react-router-dom'

import { CompositeButton } from '../../../../components/Button'
import Image from 'packages/components/common/Image'
import getImagineUrl from 'packages/core/helpers/getImagineUrl'

import style from './style.module.scss'

const Logo = ({ image }) => (
  <div className={style.footerLogoContainer}>
    <div className={style.logo}>
      <Link to="/">
        <Image
          src={getImagineUrl('logo.png', 'main_header_logo')}
          alt=""
          responsive
        />
      </Link>
    </div>
    <div className="hidden-xs">
      <CompositeButton icon="angle-right" to="/contacts">
        Связаться с нами
      </CompositeButton>
    </div>
  </div>
)

export default Logo
