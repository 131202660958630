import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { get, orderBy } from 'lodash/fp'

import { FETCH_OFFICES } from './constants'
// import { IOffice } from 'packages/core/records/Office/Office'

const selectRequest = getQuery({ type: FETCH_OFFICES })

const sortOffice /*: (items: IOffice[]) => IOffice[]*/ = orderBy(
  /*<IOffice>*/ [
    'isMain',
    'priority',
    get('address.city'),
    get('address.street'),
  ],
  ['desc', 'asc', 'asc', 'asc']
)

export const officesSelector = createSelector(
  selectRequest,
  ({ data, ...state }) => {
    return {
      ...state,
      data: data ? sortOffice(data.toJS()) : [],
    }
  }
)
