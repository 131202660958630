import { List, Record, RecordOf } from 'immutable'

import { makeList } from './CollectionFactory'
import { Address, AddressProps } from './Address'
import { PhoneProps, PhonesCollection } from './Phone'
import { Map, MapProps } from './Map'
import { SocialNetworkProps, SocialNetworksCollection } from './SocialNetwork'
import { BusinessHours } from './BusinessHours'

export type CompanyProps = {
  id?: string
  name: string
  address?: Address | AddressProps
  phones?: PhoneProps[] | PhonesCollection
  socialNetworks?: SocialNetworkProps[] | SocialNetworksCollection
  messengers?: any
  email: string
  businessHours?: any
  map?: MapProps | Map
  isSalesPoint: boolean
  offices: Company[] | CompaniesCollection
  salesPoints: Company[] | CompaniesCollection
}
export type Company = RecordOf<CompanyProps>
export type CompaniesCollection = List<Company>

const defaultValues: CompanyProps = {
  id: undefined,
  name: '',
  address: undefined,
  phones: undefined,
  socialNetworks: undefined,
  messengers: undefined,
  email: '',
  businessHours: undefined,
  map: undefined,
  isSalesPoint: false,
  offices: [],
  salesPoints: [],
}

const mapper = ({
  id,
  address,
  map,
  offices,
  phones,
  socialNetworks,
  businessHours,
  ...props
}: CompanyProps): CompanyProps => {
  return {
    id: id ? String(id) : undefined,
    ...props,
    address: Address(address),
    map: Map(map),
    offices: CompaniesCollection((offices || []) as CompanyProps[]),
    salesPoints: CompaniesCollection((offices || []) as CompanyProps[]).filter(
      office => office.isSalesPoint
    ),
    phones: PhonesCollection((phones || []) as PhoneProps[]),
    socialNetworks: SocialNetworksCollection(
      (socialNetworks || []) as SocialNetworkProps[]
    ),
    businessHours: BusinessHours(businessHours),
  }
}

const makeCompany: Record.Factory<CompanyProps> = Record(
  defaultValues,
  'CompanyRecord'
)

export const Company = (props?: CompanyProps): Company =>
  makeCompany(props ? mapper(props) : undefined)
export const CompaniesCollection = makeList(Company)
