import React from 'react'
import FontAwesome from 'react-fontawesome'
import { DropdownButton } from 'react-bootstrap'
import { intersection, isEmpty, uniqueId } from 'lodash'

import MenuItem from './MenuItem'

const menu = [
  { name: 'Личный кабинет', icon: 'user', link: '/cabinet' },
  {
    name: 'Админ. панель',
    icon: 'tachometer',
    link: '/admin',
    isRemote: true,
    roles: ['admin'],
  },
  { name: 'Баланс', icon: 'rub', link: '/balance' },
  { name: 'Корзина', icon: 'shopping-cart', link: '/cart' },
  { name: 'Заказы', icon: 'file-text-o', link: '/orders' },
  { name: 'Гараж', icon: 'car', link: '/garage' },
]

const UserMenu = ({ user, loading, logout, children, ...props }) => (
  <DropdownButton
    pullRight
    id={uniqueId('user-button-')}
    title={
      <>
        <span className="visible-sm-inline visible-md-inline visible-lg-inline">
          {user.firstName}&nbsp;
        </span>
        <FontAwesome name="user" />
      </>
    }
    {...props}
  >
    <MenuItem key="1" header>
      {user.fullName}
    </MenuItem>
    <MenuItem divider />
    {menu
      .filter(
        ({ roles }) => !roles || !isEmpty(intersection(user.roles, roles))
      )
      .map(({ name, ...props }, idx) => (
        <MenuItem key={idx} {...props}>
          {name}
        </MenuItem>
      ))}
    <MenuItem divider />
    <MenuItem eventKey={3.4} icon="sign-out" onSelect={logout}>
      Выйти
    </MenuItem>
  </DropdownButton>
)

export default UserMenu
