import { List, Record, RecordOf } from 'immutable'
import moment, { MomentInput } from 'moment'

import { makeList } from '../../CollectionFactory'
import { User, UserProps } from '../../User'

// import { EntityRecord, ListRecord } from '../../../utils/CollectionRecord'
// import { User } from 'core/records'

// const CommentRecord = EntityRecord(
//   {
//     author: null,
//     createdAt: null,
//     content: null,
//   },
//   ({ author, createdAt }: any) => ({
//     author: User(author),
//     createdAt: moment(createdAt),
//   })
// )

// export const CommentsList = (CommentRecord.List = ListRecord(CommentRecord))

// export default CommentRecord

export type Comment = RecordOf<CommentProps>
export type CommentsCollection = List<Comment>

export type CommentProps = {
  author: UserProps | User
  createdAt: MomentInput
  content: string
}

const defaultValues: CommentProps = {
  author: User(),
  createdAt: '',
  content: '',
}

const mapper = ({
  author,
  createdAt,
  ...props
}: CommentProps): CommentProps => ({
  ...props,
  author: User(author),
  createdAt: moment(createdAt),
})

const makeComment: Record.Factory<CommentProps> = Record(
  defaultValues,
  'CommentRecord'
)

export const Comment = (props?: CommentProps): Comment =>
  makeComment(props ? mapper(props) : undefined)
export const CommentsCollection = makeList(Comment)
