import { find, first } from 'lodash'

import { EntityRecord } from '../../utils/CollectionRecord'
import BusinessHoursRecord from './BusinessHoursRecord'
import AddressRecord from './Address'
import MapRecord from './Map'
import PhoneRecord from './Phone'
import SocialNetwork from './SocialNetwork'

const CompanyRecord = EntityRecord(
  {
    name: null,
    foundationYear: null,
    address: AddressRecord(),
    mainPhone: null,
    phones: PhoneRecord.List(),
    socialNetworks: SocialNetwork.List(),
    businessHours: BusinessHoursRecord(),
    messengers: null,
    email: null,
    map: MapRecord(),
  },
  ({ address, phones, map, businessHours, socialNetworks }) => {
    return {
      address: AddressRecord(address),
      mainPhone: PhoneRecord(find(phones, 'isMain') || first(phones)),
      phones: PhoneRecord.List(phones),
      socialNetworks: SocialNetwork.List(socialNetworks),
      businessHours: BusinessHoursRecord({ days: businessHours }),
      map: MapRecord(map),
    }
  }
)
export default CompanyRecord
