import { List, Record, RecordOf } from 'immutable'

import { makeList } from './CollectionFactory'

export type ContractProps = {
  id?: string
  balance: number
  creditLimit: number
  defermentPayment: number
  overdueDebt: number
  currency?: string
  isAccounting: false
  isCurrent: boolean
  isForOrder: boolean
  isOrderDeny: boolean
  isOverdueCreditLimit: boolean
  isOverdueDebt: boolean
  name: string
  number: string
  type: string
}

export type Contract = RecordOf<ContractProps>
export type ContractsCollection = List<Contract>

const defaultValues: ContractProps = {
  id: undefined,
  balance: 0,
  creditLimit: 0,
  defermentPayment: 0,
  overdueDebt: 0,
  currency: undefined,
  isAccounting: false,
  isCurrent: false,
  isForOrder: false,
  isOrderDeny: false,
  isOverdueCreditLimit: false,
  isOverdueDebt: false,
  name: '',
  number: '',
  type: '',
}

const ContractRecord: Record.Factory<ContractProps> = Record(defaultValues)

export const Contract = (props: ContractProps): Contract =>
  ContractRecord(props)

export const ContractsCollection = makeList(Contract)
