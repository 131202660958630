export const debug = process.env.NODE_ENV !== 'production'

export const apiURL = process.env.REACT_APP_API_URL
export const imageURL = process.env.REACT_APP_IMAGE_URL

export { default as ilcats } from './ilcats'

export const metrics = {
  yandex: {
    accounts: JSON.parse(process.env.REACT_APP_METRIC_YANDEX || '[]'),
  },
}
