import { assign, reduce, isArray, isPlainObject, isString } from 'lodash'

const normalizeParams = params =>
  reduce(
    params,
    (result, val) => {
      if (isPlainObject(val)) {
        assign(result, val)
      } else if (isString(val)) {
        assign(result, { [val]: val })
      } else if (isArray(val)) {
        assign(result, normalizeParams(val))
      }
      return result
    },
    {}
  )

export default normalizeParams
