import { Record, RecordOf } from 'immutable'
import { chain, replace } from 'lodash'
import { MapPlace, IMapPlace } from './MapPlace'

export type AddressProps = {
  city?: string
  country?: string
  street?: string
  line1?: string
  line2?: string
  line3?: string
  mapPlace?: MapPlace | IMapPlace
  fullAddress?: string
}

export type Address = RecordOf<AddressProps>

const defaultValues: AddressProps = {
  city: undefined,
  country: undefined,
  street: undefined,
  line1: undefined,
  line2: undefined,
  line3: undefined,
  mapPlace: MapPlace(),
  fullAddress: undefined,
}

const mapper = ({ mapPlace, ...props }: AddressProps): AddressProps => ({
  ...props,
  mapPlace: MapPlace(mapPlace),
  fullAddress: chain(props)
    .pick(['country', 'city', 'street', 'line1', 'line2', 'line3'])
    .mapValues(value => (value ? replace(value, /\s+/g, '\u00A0') : undefined))
    .values()
    .filter()
    .join(', ')
    .value(),
})

const makeAddress: Record.Factory<AddressProps> = Record(
  defaultValues,
  'AddressRecord'
)

export const Address = (props?: AddressProps): Address =>
  makeAddress(props ? mapper(props) : undefined)
