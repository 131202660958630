import React from 'react'

import BaseUserButton from 'packages/components/common/UserButton'

import { LoginModal } from 'app/components/Login'

const UserButton = ({ userComponent, guestComponent }) => (
  <BaseUserButton
    guestComponent={<LoginModal button={guestComponent} />}
    userComponent={userComponent}
  />
)

export default UserButton
