import { compose, withProps } from 'recompose'

import ErrorTemplate from './ErrorTemplate'

type ErrorProps = {
  children: any
  code?: number | string
  error: string
  description?: string
}

export const withError = ({ code, error, description }: ErrorProps) =>
  compose<any, ErrorProps>(
    withProps({
      code,
      error,
      description,
    })
  )(ErrorTemplate)
