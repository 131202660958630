import {
  APPLY_USER_ROLES,
  CLEAR_USER,
  FETCH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER,
  REGISTER_USER,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD,
} from './constants'

// import { ReducerRecord } from './records'
import { User } from 'packages/core/records'

export function fetchUser() {
  return {
    type: FETCH_USER,
    request: {
      url: '/user',
    },
    meta: {
      asPromise: true,
      resetOn: [CLEAR_USER],
      getDefaultData: (multiple: boolean) => User(),
      getData(state: any, action: any) {
        return User(action.data || {})
      },
    },
  }
}

type CredientalsProps = {
  useranme: string
  password: string
}

export function login(
  credientals: CredientalsProps,
  { onSuccess = Function, onFailure = Function } = {}
) {
  return {
    type: LOGIN_USER,
    asPromise: true,
    meta: {
      credientals,
      onSuccess,
      onFailure,
      asPromise: true,
    },
  }
}

export function logout() {
  return {
    type: LOGOUT_USER,
  }
}

// https://vivatauto.com/api/user/edit
// irstName
// middleName
// lastName
// address
// phone
// email
// markup
export function updateUser(profile: any) {
  return {
    type: UPDATE_USER,
    request: {
      url: '/user/edit',
      method: 'post',
      data: profile,
    },
    meta: {
      profile,
      asPromise: true,
      mutations: {
        [FETCH_USER]: {
          updateData(state: any, action: any) {
            const profile = action.meta.profile
            return state.data.merge(profile)
          },
          local: true,
        },
      },
    },
  }
}

// curl 'https://vivatauto.com/api/user/registration'  --data-binary '{"lastName":"aaa","firstName":"bbb","address":"г Москва, поселение Мосрентген, поселок завода Мосрентген, гск Полюс","email":"email@email.com","plainPassword":{"second":"asdqwe","first":"asdqwe"},"phone":"+7 (000) 000-00-00","captcha":"03AOLTBLSDQx60HO2DrVVX9vClCYqOwlrIxWchMStzB8ayfvzjVUfdWnvGalMd55qBGe7GCLFv_grP9nXAGPFVXn-J2TNfVKYSobWhJjFD1j6CgB_pLw7T9ecKs8HnBclSMdN80E-idME8x6PyCbtQtSkXdGjxjwE1UC1XVdfOOX8bUd1aBuB6UwdASiCrOevfMPXitUgHr_Gp-VH0nmI7M4bcpZRAR8RzpUKU6tVOTNWoGFFpdJ1OJtQxuTOh5CcAJyq4c3gY4L6MuwPtHS2husFt1dBZ184uWs0r6eh0qCFJAmpoUgM939sSIzBsyn22pkTR0Q6aR0zD0CSarIE5QMSJCM_nunIR0lR2nBSROBT83WZaAUDa8FvcExCMJQhdRyJya_O8EsVk2kCIxYXleebzmTWnhRZfO-h3cD5bsyExeu1pnTf6ZxXqpgtgml1lc_YidsjWaXCTcmPKa8TP140obiZYcOSaewfC7Vxt3IWRn8C1YKYNa5byPqcN8iW9n-lPpZs4DHiU"}'
export function registerUser(profile: any) {
  return {
    type: REGISTER_USER,
    request: {
      url: '/user/registration',
      method: 'post',
      data: profile,
    },
    meta: {
      profile,
      asPromise: true,
      mutations: {
        [FETCH_USER]: {
          updateData(state: any, action: any) {
            const user = User(action.meta.profile)
            return user
          },
          local: true,
        },
      },
    },
  }
}
// https://vivatauto.com/resetting/reset/ANuMY36jm5QGFRCOAMYy5P-FLDhVkcCeVV7OBuhNwwU
// 'https://vivatauto.com/resetting/reset/ANuMY36jm5QGFRCOAMYy5P-FLDhVkcCeVV7OBuhNwwU'  --data 'fos_user_resetting_form%5BplainPassword%5D%5Bfirst%5D=BarguziN155624&fos_user_resetting_form%5BplainPassword%5D%5Bsecond%5D=BarguziN155624'

// Запрос на восстановление пароля успешно отправлен. Проверьте email
// https://vivatauto.com/api/user/resetpassword?email=tomyspam@mail.ru
type ResetPasswordRequestProps = {
  email: string
}

export function resetPasswordRequest({ email }: ResetPasswordRequestProps) {
  return {
    type: RESET_PASSWORD_REQUEST,
    request: {
      url: '/user/resetpassword',
      params: { email },
    },
    meta: {
      asPromise: true,
    },
  }
}

export type PasswordProps = {
  plainPassword: {
    first: string
    second: string
  }
}

type ResetPasswordProps = {
  token: string
} & PasswordProps

export function resetPassword({ token, plainPassword }: ResetPasswordProps) {
  return {
    type: RESET_PASSWORD,
    request: {
      url: `/user/resetting/reset/${token}`,
      method: 'post',
      data: { plainPassword },
    },
    meta: {
      password: plainPassword.first,
      asPromise: true,
    },
  }
}
// fos_user_resetting_form[plainPassword][first]
// fos_user_resetting_form[plainPassword][second]

export function applyUserRoles() {
  return {
    type: APPLY_USER_ROLES,
    meta: {
      mutations: {
        [FETCH_USER]: {
          updateData({ data }: any) {
            return data.set('isGuest', !Boolean(data.get('id')))
          },
          local: true,
        },
      },
    },
  }
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  }
}
