import withForm from './withForm'
import withHandler from './withHandler'
import withRecord from './withRecord'

export { withForm, withHandler, withRecord }

export default {
  getForm: withForm,
  getHandler: withHandler,
  getRecord: withRecord,
}
