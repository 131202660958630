import lazyPage from '../../hoc/lazyPage'

export default () => [
  {
    path: '/blog',
    component: lazyPage(() => import('../../containers/Blog/BlogPostsList')),
    exact: true,
  },
  {
    path: '/blog/:category/:slug',
    component: lazyPage(() => import('../../containers/Blog/BlogPost')),
    exact: true,
  },
]
