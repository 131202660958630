import React from 'react'
import { withHandlers } from 'recompose'
import { toast } from 'react-toastify'
import { FormattedMessage } from 'react-intl'

import UserMenu from './UserMenu'

export default withHandlers({
  logout: ({ logout: doLogout, user }) => () => {
    doLogout()
    toast.success(
      <FormattedMessage
        id="component.login_logout.message.logout"
        values={{ username: user.firstName }}
      />
    )
  },
})(UserMenu)
