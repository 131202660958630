import React from 'react'
import { Modal } from 'react-bootstrap'

import ModalWindow from 'app/components/ModalWindow'
import { LoginWindow } from '../LoginWindow'

export const LoginModal = ({ button, show: initialShow = false }) => (
  <ModalWindow button={button} show={initialShow}>
    {({ handleHide, show }) => (
      <Modal show={show} onHide={handleHide} backdrop="static">
        <LoginWindow handleHide={handleHide} />
      </Modal>
    )}
  </ModalWindow>
)
