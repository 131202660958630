import { List, Record, RecordOf } from 'immutable'

import { makeList } from 'packages/core/records/CollectionFactory'

export type TagProps = {
  title: string
  slug: string
  link: string
}
export type Tag = RecordOf<TagProps>
export type TagsCollection = List<Tag>

const defaultValues: TagProps = {
  title: '',
  slug: '',
  link: '',
}

const makeTag: Record.Factory<TagProps> = Record(defaultValues, 'TagRecord')

export const Tag = (props?: TagProps): Tag => makeTag(props)
export const TagsCollection = makeList(Tag)
