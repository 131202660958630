import { useEffect, createElement } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { compose, setDisplayName, wrapDisplayName } from 'recompose'

export function useAutoScrollToTop(history) {
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const scrollToTop = get(location.state, 'scrollToTop', true)

      if (action === 'PUSH' && !scrollToTop) {
        return
      }

      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
  }, [history])
  return void 0
}

const withAutoScroll = Component => {
  return ({ history, ...props }) => {
    useAutoScrollToTop(history)
    return createElement(Component, props)
  }
}

const enchance = Component =>
  compose(
    withRouter,
    withAutoScroll,
    setDisplayName(wrapDisplayName(Component, 'withAutoScrollToTop'))
  )(Component)

export default enchance
