import { List, Record, RecordOf } from 'immutable'

import { Good, GoodProps } from './Good'
import { Vendor, VendorProps } from './Vendor'
import { Contract, ContractProps } from './Contract'
import { Offer } from './Offer'
import { makeList } from './CollectionFactory'

export type CartItemProps = {
  id?: number
  itemKey: string
  name?: string
  price: number
  amount: number
  deliveryTime: any
  isStock: boolean
  isSelected: boolean
  isReturnAllowed: boolean
  returnPercent: number
  contract?: any
  reference: string
  vendor?: VendorProps | Vendor
  good?: GoodProps | Good
  offer?: Offer
  loadingOffers: boolean
  // offers?: OffersCollection
}

const defaultValues: CartItemProps = {
  id: undefined,
  itemKey: '',
  name: undefined,
  price: 0,
  amount: 0,
  deliveryTime: null,
  isStock: false,
  isSelected: true,
  isReturnAllowed: true,
  returnPercent: 0,
  contract: undefined,
  reference: '',
  vendor: undefined,
  good: undefined,
  offer: undefined,
  loadingOffers: false,
  // offers: undefined,
}
export type CartItem = RecordOf<CartItemProps>
// export type CartItemsCollection = Map<string, CartItem>
export type CartItemsCollection = List<CartItem>

const makeCartItem: Record.Factory<CartItemProps> = Record(
  defaultValues,
  'CartItemRecord'
)

const mapper = ({
  contract,
  good,
  vendor,
  ...props
}: CartItemProps): CartItemProps => ({
  ...props,
  contract: Contract(contract as ContractProps),
  good: Good(good as GoodProps),
  vendor: Vendor(vendor as VendorProps),
})

export const CartItem = (props: CartItemProps): CartItem =>
  makeCartItem(mapper(props))

// const createId = ({ itemKey, contract, reference }: CartItemProps): string =>
//   [itemKey, contract.id, reference].filter(Boolean).join('|')

// export const CartItemsCollection = makeMap(CartItem, createId)
export const CartItemsCollection = makeList(CartItem)
