import React from 'react'
import moment from 'moment'
import { Grid, Col, Row } from 'react-bootstrap'

import FooterNavbar from './FooterNavbar'
import Logo from './Logo'
import FooterWidgets from './FooterWidgets'

import style from './style.module.scss'

const Footer = ({ company }) => (
  <footer className={style.footer}>
    <div className={style.footerDecor}></div>
    <div className={style.footerTop}>
      <Grid>
        <Row>
          <Col xsHidden sm={8} smPush={2} md={10} mdPush={1}>
            <FooterNavbar />
          </Col>
        </Row>
        <Logo />
        <Row>
          <Col xsHidden sm={4} md={3}></Col>
        </Row>
        <Row>
          <FooterWidgets />
        </Row>
      </Grid>
    </div>
    <div className={style.footerBottom}>
      <Grid>
        <div className={style.footerSocialButtons}>
          <a
            title="Разработка интернет-магазинов автозапчастей"
            href="https://itonix.ru"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src="https://itonix.ru/wp-content/uploads/2020/01/logo_itonix_gray_300px.png"
              width="100"
              alt="Разработка интернет-магазинов автозапчастей. Программа учета в магазине автозапчастей."
            />
          </a>
        </div>
        {/* <div className={style.footerSocialButtons}>
          <a href="/">
            <FontAwesome name="google-plus-square" />
          </a>
          <a href="/">
            <FontAwesome name="youtube-square" />
          </a>
          <a href="/">
            <FontAwesome name="facebook-square" />
          </a>
          <a href="/">
            <FontAwesome name="twitter-square" />
          </a>
        </div> */}
        <span className={style.footerCopyright}>
          © {moment().format('YYYY')} {company.name}. All Rights Reserved.
        </span>
      </Grid>
    </div>
  </footer>
)

export default Footer
