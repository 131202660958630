import { createElement } from 'react'
import { compose, getContext, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import { FormattedMessage /*, injectIntl*/ } from 'react-intl'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { noop } from 'lodash'
import { toast } from 'react-toastify'

import LoginForm from './LoginForm'

const withContext = getContext({
  login: PropTypes.func.isRequired,
})

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label('E-Mail')
    .email()
    .required(),
  password: Yup.string()
    .label('Пароль')
    .required(),
})

const withComponentHandlers = withHandlers({
  onSuccess: () => user => {
    toast.success(
      createElement(FormattedMessage, {
        id: 'component.login_logout.message.login',
        values: {
          username: user.firstName,
        },
      })
    )
  },
  onFailure: () => () => {
    toast.error(
      createElement(FormattedMessage, {
        id: 'component.login_logout.error.bad_credentials',
      })
    )
  },
})

const withForm = withFormik({
  async handleSubmit(
    values,
    {
      setSubmitting,
      props: { login, onSuccess = noop, onFailure = noop, ...props },
    }
  ) {
    try {
      const user = await login(values)
      onSuccess(user)
    } catch (error) {
      try {
        onFailure()
      } catch (e) {}
    } finally {
      setSubmitting(false)
    }
  },
  mapPropsToValues() {
    return {
      username: '',
      password: '',
    }
  },
  validationSchema,
})

export default compose(withContext, withComponentHandlers, withForm)(LoginForm)
