import React from 'react'
import FontAwesome from 'react-fontawesome'
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap'

import BaseFormikFormControl from '../FormControl'

const FormikFormGroup = ({
  field = {},
  form = {},
  label,
  help,
  FormControl: FormikFormControl = BaseFormikFormControl,
  ...props
}) => {
  const { touched = {}, errors = {} } = form
  const hasError = touched[field.name] && errors[field.name]
  return (
    <FormGroup validationState={hasError ? 'error' : null}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormikFormControl field={field} {...props} />
      {hasError ? (
        <HelpBlock>
          <FontAwesome fixedWidth name="exclamation-circle" />{' '}
          {errors[field.name]}
        </HelpBlock>
      ) : (
        help && <HelpBlock>{help}</HelpBlock>
      )}
      {hasError && <FormControl.Feedback />}
    </FormGroup>
  )
}

export default FormikFormGroup
