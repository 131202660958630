import React from 'react'
import FontAwesome from 'react-fontawesome'
import cn from 'classnames'
import { Badge, Navbar } from 'react-bootstrap'
import { Affix } from 'react-overlays'

import style from './style.module.scss'

import Image from 'packages/components/common/Image'
import Link from 'packages/components/common/Link'
import Currency from 'packages/components/common/Currency'
import SearchField from '../SearchField'
import UserMenu from './UserMenu'
import UserButton from 'app/components/UserButton'
import { Button } from 'app/components/Button'

import logo from 'app/images/layout/SM-logo-slim.png'

const TopToolbar = ({ user, cartAmount, balance }) => (
  <Affix affixClassName={style.affix} offsetTop={150}>
    <Navbar fixedTop inverse={false} className={style.container}>
      <span className={style.left}>
        <Link
          to="/"
          className={cn(
            'visible-sm-inline',
            'visible-md-inline',
            'visible-lg-inline',
            style.brand
          )}
        >
          <Image src={logo} className={style.image} />
        </Link>
        <SearchField className={style.search} />
      </span>
      <UserButton
        guestComponent={
          <Button className={style.button}>
            <div className="visible-sm-block visible-md-block visible-lg-block pull-left">
              Вход | Регистрация&nbsp;
            </div>
            <FontAwesome name="user-o" />
          </Button>
        }
        userComponent={<UserMenu className={style.button} />}
      />
      {!user.data.isGuest && (
        <span className="visible-sm-block visible-md-block visible-lg-block">
          &nbsp;
          <Button className={style.button} bsStyle="info" to="/cart">
            <strong>
              <FontAwesome name="shopping-basket" />
            </strong>
            {cartAmount > 0 && (
              <>
                {' '}
                <Badge>
                  <small>{cartAmount}</small>
                </Badge>
              </>
            )}
          </Button>
          &nbsp;
          <Button
            className={cn(style.button, 'visible-lg-inline-block')}
            bsStyle={balance < 0 ? 'danger' : 'success'}
            to="/balance"
          >
            Баланс &nbsp;
            <Badge>
              <Currency value={balance} />
            </Badge>
          </Button>
        </span>
      )}
    </Navbar>
  </Affix>
)

export default TopToolbar
