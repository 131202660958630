import { put, select, putResolve, takeEvery } from 'redux-saga/effects'
import { getItem, setItem, removeItem } from 'redux-effects-localstorage'
import { success /*, sendRequest*/ } from 'redux-saga-requests'
import { /*LOCATION_CHANGE,*/ getSearch } from 'connected-react-router'
import qs from 'qs'
import { get, has } from 'lodash'
import { replace, LOCATION_CHANGE, getLocation } from 'connected-react-router'

import {
  AUTHENTICATE,
  INITIALIZE,
  SET_TOKENS,
  TOKEN,
  REFRESH_TOKEN,
  SWITCH_USER,
  LOGOUT,
  CLEAR_TOKENS,
} from './constants'
import { setTokens, clearTokens, switchUser } from './actions'
import { fetchUser } from '../user/actions'
import { switchUserSelector } from './selectors'
import { fetchCart } from '../cart/actions'

function* watchInitialize() {
  const token = yield putResolve(getItem(TOKEN))

  if (token) {
    const refreshToken = yield putResolve(getItem(REFRESH_TOKEN))
    yield put(setTokens({ token, refreshToken }))

    const search = yield select(getSearch)
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const user = get(query, SWITCH_USER)
    if (user) {
      yield put(switchUser(user))
    }

    try {
      yield putResolve(fetchUser())
      yield putResolve(fetchCart())
    } catch (error) {}
  }
}

function* watchSetTokens(action) {
  const { token, refreshToken } = action.meta.payload
  yield put(setItem(TOKEN, token))
  yield put(setItem(REFRESH_TOKEN, refreshToken))
}

function* watchClearTokens() {
  yield put(removeItem(TOKEN))
  yield put(removeItem(REFRESH_TOKEN))
}

function* watchSuccessAuthentication(action) {
  const { token, refresh_token: refreshToken } = action.data
  yield put(setTokens({ token, refreshToken }))
}

function* watchLogout() {
  yield put(clearTokens())
}
const switchUserKey = '_switch_user'
function* watchLocationChange(action) {
  const switchUser = yield select(switchUserSelector)
  const location = yield select(getLocation)
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  if (switchUser && !has(query, switchUserKey)) {
    Object.assign(query, {
      [switchUserKey]: switchUser,
    })

    const search = qs.stringify(query, { addQueryPrefix: true })
    Object.assign(location, {
      query,
      search,
    })
    yield put(replace(location))
  }
}

export default function* saga() {
  yield takeEvery(INITIALIZE, watchInitialize)
  yield takeEvery(SET_TOKENS, watchSetTokens)
  yield takeEvery(LOGOUT, watchLogout)
  yield takeEvery(CLEAR_TOKENS, watchClearTokens)
  yield takeEvery(success(AUTHENTICATE), watchSuccessAuthentication)
  yield takeEvery(LOCATION_CHANGE, watchLocationChange)
}
