import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { first } from 'lodash'

import Image from 'packages/components/common/Image'

import style from './style.module.scss'

const GoodItem = ({ good }) => (
  <div className={style.containerNew}>
    <div className={style.icon}>
      {first(good.thumbs) && (
        <Image src={first(good.thumbs)} circle thumbnail />
      )}
    </div>
    <article>
      <h6>
        <strong>{good.brandName}</strong> {good.number}
      </h6>
      <em>{good.description}</em>
    </article>
  </div>
)
// const GoodItem = ({ good }) => (
//   <Grid fluid className={style.container}>
//     <Row>
//       {first(good.thumbs) && (
//         <Image src={first(good.thumbs)} className={style.image} />
//       )}
//       <Col xs={12} className={style.text}>
//         <Row>
//           <Col xs={12}>
//             <strong>{good.brandName}</strong> {good.number}
//           </Col>
//           <Col xs={12}>
//             <em>{good.description}</em>
//           </Col>
//         </Row>
//       </Col>
//     </Row>
//   </Grid>
// )

export default GoodItem
