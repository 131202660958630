import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import Immutable from 'immutable'
import {
  requestsCacheMiddleware,
  requestsPromiseMiddleware,
} from 'redux-saga-requests'
import { connectRouter } from 'connected-react-router'
import { networkReducer } from 'redux-saga-requests'

import { debug } from 'packages/core/config'
import { reducer } from 'packages/core/features'
import rootSaga from 'packages/core/features/saga'

// import { composeWithDevTools } from 'redux-devtools-extension'
import middleware from './middleware'
import { createReducerManager } from './createReducerManager'

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose

  if (debug) {
    const devToolsOptions = {
      trace: true,
      serialize: {
        immutable: Immutable,
      },
    }
    const composeWithDevTools = require('redux-devtools-extension')
      .composeWithDevTools
    composeEnhancers = composeWithDevTools(devToolsOptions)
  }

  const reduxSagaMonitorOptions = {}
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)

  const middlewares = [
    requestsCacheMiddleware(),
    requestsPromiseMiddleware(/*{ auto: true }*/),
    sagaMiddleware,
    routerMiddleware(history),
    ...middleware,
  ]

  const enhancers = [applyMiddleware(...middlewares)]

  const reducerManager = createReducerManager({
    ...reducer,
    network: networkReducer({}),
    router: connectRouter(history),
  })

  const store = createStore(
    // createReducer(),
    reducerManager.reduce,
    initialState,
    composeEnhancers(...enhancers)
  )

  sagaMiddleware.run(rootSaga)

  // Extensions
  store.reducerManager = reducerManager
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  return store
}
