import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'

import { FETCH_USER } from './constants'
import { User } from 'packages/core/records'
// import { ReducerRecord } from './records'

const selectState = getQuery({ type: FETCH_USER })

export const userRecordSelector = createSelector(
  selectState,
  ({ data, ...state }) => ({
    ...state,
    data: data || User(),
  })
)

export const userSelector = createSelector(
  userRecordSelector,
  ({ data, ...state }) => ({
    ...state,
    data: data.toJS(),
  })
)
