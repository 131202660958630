import { withProps } from 'recompose'
import { get } from 'lodash'

import messages from '../translations'

if (!Intl.PluralRules) {
  require('intl-pluralrules')
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill')
  // require('@formatjs/intl-relativetimeformat/dist/include-aliases') // Optional, if you care about edge cases in locale resolution, e.g zh-CN -> zh-Hans-CN
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en') // Add locale data for de
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ru') // Add locale data for de
}

export const formats = {
  number: {
    РУБ: {
      style: 'currency',
      currency: 'RUB',
    },
    USD: {
      style: 'currency',
      currency: 'USD',
    },
  },
}

export const withIntlSettings = withProps(({ locale = 'ru' }) => ({
  intlSettings: {
    locale,
    formats,
    messages: get(messages, locale, {}),
  },
}))
