import * as actions from './actions'
import reducer from './reducer'
import * as selectors from './selectors'
import { KEY } from './constants'
import saga from './saga'
import container from './container'

export { KEY, actions, reducer, saga, selectors }

export default container
