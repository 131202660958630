import { put, takeLatest, delay, select } from 'redux-saga/effects'
import { isEmpty, isString, size, trim } from 'lodash'
import { push, LOCATION_CHANGE } from 'connected-react-router'

import { clearGoods, fetchGoods, setQuery } from './actions'
import { SET_QUERY, SET_CURRENT } from './constants'
import { selectQuery } from './selectors'

function* handleInput({ payload, meta }) {
  if (isString(payload)) {
    const query = trim(payload).toLowerCase()
    const { minLength, debounce } = meta

    yield delay(debounce)
    if (size(query) >= minLength) {
      yield put(fetchGoods(query))
    } else if (size(query) === 0) {
      yield put(clearGoods())
    }
  }
}

function* handleSelect({ payload: good }) {
  if (good) {
    yield put(setQuery(good.name))
    yield put(push(good.searchLink))
  }
}

function* handleLocationChange({ payload }) {
  const {
    pathname,
    query: { number },
  } = payload.location

  if (pathname === '/search') {
    const query = yield select(selectQuery)
    if (isEmpty(query)) yield put(setQuery(number))
  } else {
    yield put(setQuery())
  }
}

export default function* saga() {
  yield takeLatest(SET_QUERY, handleInput)
  yield takeLatest(SET_CURRENT, handleSelect)
  yield takeLatest(LOCATION_CHANGE, handleLocationChange)
}
