import { connect } from 'react-redux'
import ym from 'react-yandex-metrika'
import {
  branch,
  compose,
  lifecycle,
  mapProps,
  renderNothing,
  withHandlers,
  withProps,
} from 'recompose'
import { isEmpty, pick } from 'lodash'
import { createStructuredSelector } from 'reselect'

import { metrics } from 'packages/core/config'
import history from 'packages/core/utils/history'
import { userSelector } from 'packages/core/features/user/selectors'
import { UserProps } from 'packages/core/records/User'

import YandexMetrika, {
  OptionsProps,
  YandexMetrikaProps,
} from './YandexMetrika'

const ymConfig: OptionsProps = {
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: 5,
  webvisor: true,
  defer: true,
}

type ContainerProps = {
  history: any
  user: { data: UserProps }
} & YandexMetrikaProps

type ContainerHandlers = {
  urlChangeHandler: () => void
  applyUser: () => void
}

const mapStateToProps = createStructuredSelector({
  user: userSelector,
})

const withConnect = connect(mapStateToProps)

const enhancer = compose<YandexMetrikaProps, {}>(
  withConnect,
  withProps<{ history: any } & YandexMetrikaProps, {}>({
    accounts: metrics.yandex.accounts,
    options: ymConfig,
    history,
  }),
  branch(
    ({ accounts }: YandexMetrikaProps) => isEmpty(accounts),
    renderNothing
  ),
  withHandlers<ContainerProps, ContainerHandlers>({
    urlChangeHandler: ({ history }) => () => {
      const { pathname, search } = history.location
      ym('hit', `${pathname}${search}`)
    },
    applyUser: ({ user }) => () => {
      const { isGuest, id, isWholesale } = user.data
      if (!isGuest) {
        ym('setUserID', id)
        ym('userParams', { id, isWholesale })
      }
    },
  }),
  lifecycle<any, any, any>({
    componentDidMount() {
      this.props.history.listen(this.props.urlChangeHandler)
      this.props.applyUser()
    },
    componentDidUpdate() {
      this.props.applyUser()
    },
  }),
  mapProps<YandexMetrikaProps, any>(props => pick(props, 'accounts', 'options'))
)
export default enhancer(YandexMetrika)
