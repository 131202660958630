import { List, Record, RecordOf } from 'immutable'

import { makeList } from './CollectionFactory'

export type PaymentMethodProps = {
  id?: number | string
  commission?: number
  description: string | null
  isDefault: boolean
  message: string | null
  name: string
  provider: null | string
}

const defaultValues: PaymentMethodProps = {
  id: undefined,
  commission: 0,
  description: null,
  isDefault: false,
  message: null,
  name: '',
  provider: null,
}

export type PaymentMethod = RecordOf<PaymentMethodProps>
export type PaymentMethodsCollection = List<PaymentMethod>

const makePaymentMethod: Record.Factory<PaymentMethodProps> = Record(
  defaultValues,
  'PaymentMethodRecord'
)

const mapper = ({ id, ...props }: PaymentMethodProps): PaymentMethodProps => ({
  id: String(id),
  ...props,
})

export const PaymentMethod = (props: PaymentMethodProps): PaymentMethod =>
  makePaymentMethod(mapper(props))

export const PaymentMethodsCollection = makeList(PaymentMethod)
