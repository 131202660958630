import { get, isNil, isEqual } from 'lodash'
import url from 'url'

function isExternalLink(href: string = ''): boolean {
  const link = url.parse(href)

  return (
    !isNil(link.host) &&
    (!isEqual(link.protocol, get(window, 'location.protocol')) ||
      !isEqual(link.host, get(window, 'location.host')))
  )
}

export default isExternalLink
