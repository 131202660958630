import { OrderedMap, List, Record } from 'immutable'
import { get, noop, isFunction /*create*/ } from 'lodash'

export const EntityRecord = (
  initialValues: {} = {},
  mapper: Function = noop,
  toJSON: Function | null = null
) => (values: {} = {}) => {
  const EntityRecord = Record(initialValues)
  if (isFunction(toJSON)) {
    EntityRecord.prototype.toJSON = function() {
      return toJSON(this)
    }
  }
  // const set = EntityRecord.prototype.set

  // EntityRecord.prototype = create(EntityRecord.prototype, {
  //   set: function(k: string, v: any) {
  //     return set.call(this, k, get(mapper({ [k]: v }), k, v))
  //   },
  // })

  return EntityRecord(Object.assign({}, values, mapper(values)))
}
// Record(initialValues)(Object.assign({}, values, mapper(values)))

export const CollectionRecord = (
  RecordModel: any,
  idProperty: string = 'id'
) => (data: Array<{}> = []): OrderedMap<string | number, typeof RecordModel> =>
  OrderedMap(
    data.map((props: {}, index: number) => [
      get(props, idProperty, index),
      RecordModel(props),
    ])
  )

export const ListRecord = (RecordModel: any) => (
  data: Array<{}> = []
): List<typeof RecordModel> => List(data.map((props: {}) => RecordModel(props)))
