import React from 'react'
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from 'react-bootstrap'
import ConditionalWrap from 'conditional-wrap'
import { LinkContainer } from 'react-router-bootstrap'

import isExternalLink from 'packages/core/helpers/isExternalLink'
import Tooltip from 'packages/components/bs3/Tooltip'

export type ButtonProps = BaseButtonProps & {
  href?: string
  to?: string
  tooltip?: React.ReactNode
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
}

export const Button: React.FC<ButtonProps> = ({
  tooltip = '',
  tooltipPlacement = 'top',
  children,
  ...props
}) => (
  <ConditionalWrap
    condition={!props.disabled && !!tooltip}
    wrap={(children: any) => (
      <Tooltip placement={tooltipPlacement} text={tooltip}>
        {children}
      </Tooltip>
    )}
  >
    <ConditionalWrap
      condition={(!!props.href && !isExternalLink(props.href)) || props.to}
      wrap={(children: any) => (
        <LinkContainer to={(props.href || props.to) as string}>
          {children}
        </LinkContainer>
      )}
    >
      <BaseButton
        {...props}
        {...(isExternalLink(props.href) && {
          target: 'blank',
          rel: 'nofollow noopener',
        })}
      >
        {children}
      </BaseButton>
    </ConditionalWrap>
  </ConditionalWrap>
)

export default Button
