import { mapProps } from 'recompose'

import {
  Layout,
  LayoutProps,
  withLayout as withBaseLayout,
} from 'packages/components/common/Layout'

import { Content as contentComponent } from './Content'
import { Header as headerComponent } from './Header'
import { Footer as footerComponent } from './Footer'

export * from './Content'
export * from './Header'
export * from './Footer'

const defaultLayoutProps: Partial<LayoutProps> = {
  headerComponent,
  contentComponent,
  footerComponent,
}

const withLayoutProps = mapProps<Partial<LayoutProps>, LayoutProps>(props =>
  Object.assign({}, defaultLayoutProps, props)
)

const AppLayout = (withLayoutProps(Layout) as unknown) as typeof Layout

export default AppLayout

export const withLayout = (props: Partial<LayoutProps>) =>
  withBaseLayout(Object.assign({}, defaultLayoutProps, props))
