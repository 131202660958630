import { List, Record, RecordOf } from 'immutable'
import { chain } from 'lodash'

import { CartItemsCollection, CartItemProps } from './CartItem'
import { ContractsCollection, ContractProps } from './Contract'
import { Company, CompanyProps } from './Company'

export type UserProps = {
  id?: number
  readonly isGuest: boolean
  readonly isAdmin: boolean
  isWholesale: boolean
  firstName?: string
  middleName?: string
  lastName?: string
  readonly fullName?: string
  city?: string
  address?: string
  office?: Company | CompanyProps
  phone?: string
  email?: string
  markup: number
  roles: Array<string>
  cart: Array<CartItemProps> | List<CartItemProps>
  contracts: Array<ContractProps> | List<ContractProps>
}

export const defaultValues: UserProps = {
  id: undefined,
  isGuest: true,
  isAdmin: false,
  isWholesale: false,
  firstName: undefined,
  middleName: undefined,
  lastName: undefined,
  fullName: undefined,
  city: undefined,
  address: undefined,
  office: undefined,
  phone: undefined,
  email: undefined,
  markup: 0,
  roles: [],
  cart: CartItemsCollection(),
  contracts: ContractsCollection(),
}
export type User = RecordOf<UserProps>

const getFullName = (props: Partial<UserProps>): string =>
  chain(props)
    .pick('lastName', 'firstName', 'middleName')
    .filter()
    .join(' ')
    .value()

const mapper = <T>({
  cart,
  office,
  contracts,
  isAdmin,
  ...props
}: UserProps): UserProps => {
  return {
    ...props,
    // isGuest: isNil(props.id),
    isAdmin,
    fullName: getFullName(props),
    cart: CartItemsCollection(cart as Array<CartItemProps>),
    office: Company(office),
    contracts: ContractsCollection(contracts as Array<ContractProps>),
    roles: isAdmin ? ['admin'] : [],
  }
}

const makeUser: Record.Factory<UserProps> = Record(defaultValues, 'UserRecord')

export const User = (props?: UserProps): User =>
  makeUser(props ? mapper(props) : undefined)
