import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { isEmpty, isEqual } from 'lodash'

import libphonenumber from 'google-libphonenumber'
import { isFunction } from 'util'
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

yup.addMethod(yup.string, 'phone', function() {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: values => (
      <FormattedMessage id="yup.string.phone" values={values} />
    ),
    test: value => {
      if (!value) return true
      try {
        const phone = phoneUtil.parse(value, 'ru_RU')
        return phoneUtil.isValidNumber(phone)
      } catch (e) {
        return false
      }
    },
  })
})

yup.addMethod(yup.mixed, 'sameAs', function(ref, message) {
  return this.test({
    name: 'sameAs',
    message,
    test: function(value) {
      const other = this.resolve(ref)
      return isEmpty(other) || isEqual(other, value)
    },
  })
})

yup.addMethod(yup.number, 'multiplicity', function(
  ref,
  message = locale.number.multiplicity
) {
  return this.test({
    name: 'multiplicity',
    test: function(value) {
      const multiplicity = this.resolve(ref)

      return value % multiplicity !== 0
        ? this.createError({
            message: isFunction(message)
              ? ({ path }) =>
                  message({
                    path,
                    multiplicity,
                  })
              : message,
            path: this.path,
          })
        : true
    },
  })
})

const locale = {
  mixed: {
    default: values => (
      <FormattedMessage id="yup.mixed.default" values={values} />
    ),
    required: values => (
      <FormattedMessage id="yup.mixed.required" values={values} />
    ),
  },
  number: {
    min: values => <FormattedMessage id="yup.number.min" values={values} />,
    max: values => <FormattedMessage id="yup.number.max" values={values} />,
    multiplicity: values => (
      <FormattedMessage id="yup.number.multiplicity" values={values} />
    ),
  },
  string: {
    min: values => <FormattedMessage id="yup.string.min" values={values} />,
    max: values => <FormattedMessage id="yup.string.max" values={values} />,
    phone: values => <FormattedMessage id="yup.string.phone" values={values} />,
    validate: values => (
      <FormattedMessage id="yup.mixed.validate" values={values} />
    ),
  },
}

yup.setLocale(locale)
