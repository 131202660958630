import React from 'react'

import Image from 'packages/components/common/Image'
import style from './style.module.scss'

const LoaderImage = ({ image }) => (
  <Image
    src={image}
    className={style.image}
    alt=""
    responsive
    circle
    loader={false}
  />
)

export default LoaderImage
