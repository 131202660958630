import { createElement } from 'react'
import { configure } from 'packages/components/common/Loader'

import getImagineUrl from 'packages/core/helpers/getImagineUrl'
import LoaderText from './LoaderText'
import LoaderImage from './LoaderImage'

configure({
  image: createElement(LoaderImage, {
    image: getImagineUrl('logo.png', 'main_header_logo'),
  }),
  text: createElement(LoaderText, {}, 'Загрузка...'),
  size: 250,
  borderSize: 5,
})
