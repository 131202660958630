import { createElement } from 'react'
import { compose, getContext, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import { FormattedMessage /*, injectIntl*/ } from 'react-intl'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { get, noop } from 'lodash'
import { toast } from 'react-toastify'

import ResetPasswordForm from './ResetPasswordForm'

const withContext = getContext({
  resetPasswordRequest: PropTypes.func.isRequired,
})

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .label('E-Mail')
    .email()
    .required(),
})

const withComponentHandlers = withHandlers({
  onSuccess: () => message => {
    toast.success(message)
  },
  onFailure: () => message => {
    toast.error(
      message ||
        createElement(FormattedMessage, {
          id: 'component.login_logout.error.reset_password_error',
        })
    )
  },
})

const withForm = withFormik({
  async handleSubmit(
    values,
    {
      setSubmitting,
      props: { resetPasswordRequest, onSuccess = noop, onFailure = noop },
    }
  ) {
    try {
      const { data } = await resetPasswordRequest(values)
      onSuccess(data.message)
    } catch (error) {
      const message = get(error, 'error.response.data.errors')
      try {
        onFailure(message)
      } catch (e) {}
    } finally {
      setSubmitting(false)
    }
  },
  mapPropsToValues() {
    return {
      email: '',
    }
  },
  validationSchema,
})

export default compose(
  withContext,
  withComponentHandlers,
  withForm
)(ResetPasswordForm)
