import { Record, RecordOf } from 'immutable'
import { chain, replace } from 'lodash'

export type AddressProps = {
  country?: string
  region?: string
  city?: string
  street?: string
  building?: string
  additional?: string
  fullAddress?: string
}

export type Address = RecordOf<AddressProps>

const defaultValues: AddressProps = {
  country: undefined,
  region: undefined,
  city: undefined,
  street: undefined,
  building: undefined,
  additional: undefined,
  fullAddress: undefined,
}

const mapper = (props: AddressProps): AddressProps => ({
  ...props,
  fullAddress: chain(props)
    .pick(['region', 'city', 'street', 'building'])
    .mapValues(value => (value ? replace(value, /\s+/g, '\u00A0') : undefined))
    .values()
    .filter()
    .join(', ')
    .value(),
})

const makeAddress: Record.Factory<AddressProps> = Record(
  defaultValues,
  'AddressRecord'
)

export const Address = (props?: AddressProps): Address =>
  makeAddress(props ? mapper(props) : undefined)
