import { EntityRecord, ListRecord } from '../../utils/CollectionRecord'

const SocialNetwork = EntityRecord({
  name: null,
  icon: null,
  link: null,
})

const SocialNetworksList = ListRecord(SocialNetwork)

SocialNetwork.List = SocialNetworksList

export default SocialNetwork
