import React from 'react'

import {
  SubmitButton as BaseSubmitButton,
  SubmitButtonProps,
} from 'packages/components/bs3/Button'
import { Button } from '../Button'

export const SubmitButton: React.FC<SubmitButtonProps> = props => (
  <BaseSubmitButton buttonComponent={Button} {...props} />
)
