import React from 'react'
import FontAwesome from 'react-fontawesome'
import { renderRoutes } from 'react-router-config'
import { IntlProvider } from 'react-intl'
import { ToastContainer } from 'react-toastify'
import ScrollToTop from 'react-scroll-up'
import { Helmet } from 'react-helmet'

import Metrics from 'packages/components/common/Metrics'
import ErrorBoundary from 'app/components/ErrorBoundary'
import TopToolbar from '../components/TopToolbar'

const App = ({ routes, location, intlSettings, ...props }) => (
  <IntlProvider {...intlSettings}>
    <>
      <Helmet
        encodeSpecialCharacters={true}
        titleTemplate="%s | SM-AVTO - Интернет-магазин автозапчастей"
        defaultTitle="SM-AVTO - Интернет-магазин автозапчастей"
      >
        <html lang={intlSettings.locale} amp />
      </Helmet>
      <TopToolbar />
      <section>
        <ErrorBoundary>
          {renderRoutes(routes, props, { location })}
        </ErrorBoundary>
      </section>
      <Metrics />
      <ToastContainer />
      <ScrollToTop showUnder={160} duration={1000}>
        <FontAwesome name="arrow-circle-up" size="3x" />
      </ScrollToTop>
    </>
  </IntlProvider>
)

export default App
