import { List, Record, RecordOf } from 'immutable'

import { makeList } from '../CollectionFactory'
import { Address, AddressProps } from './Address'
import { PhoneProps, PhonesCollection } from '../Phone'
// import { Map, MapProps } from './Map'
import { SocialNetworkProps, SocialNetworksCollection } from '../SocialNetwork'
import { IWorkSchedule } from './work-schedule.interface'

// import { BusinessHours } from '../BusinessHours'

export type IOffice = {
  id?: string | number
  name: string
  address?: Address | AddressProps
  // address?: Address
  phones?: PhoneProps[] | PhonesCollection
  socialNetworks?: SocialNetworkProps[] | SocialNetworksCollection
  // messengers?: any
  emails: string[]
  isMain: boolean
  isMainRetail: boolean
  isMainWholesale: boolean
  workSchedule?: IWorkSchedule
  // map?: MapProps | Map
  // isSalesPoint: boolean
  // offices: Office[] | OfficesCollection
  // salesPoints: Office[] | OfficesCollection
}
export type Office = RecordOf<IOffice>
export type OfficesCollection = List<Office>

const defaultValues: IOffice = {
  id: undefined,
  name: '',
  address: undefined,
  phones: undefined,
  socialNetworks: undefined,
  // messengers: undefined,
  emails: [],
  workSchedule: undefined,
  isMain: false,
  isMainRetail: false,
  isMainWholesale: false,
  // map: undefined,
  // isSalesPoint: false,
  // offices: [],
  // salesPoints: [],
}

const mapper = ({
  address,
  phones,
  socialNetworks,
  // businessHours,
  ...props
}: IOffice): IOffice => {
  return {
    ...props,
    address: Address(address),
    // offices: OfficesCollection((offices || []) as IOffice[]),
    // salesPoints: OfficesCollection((offices || []) as IOffice[]).filter(
    //   office => office.isSalesPoint
    // ),
    phones: PhonesCollection((phones || []) as PhoneProps[]),
    socialNetworks: SocialNetworksCollection(
      (socialNetworks || []) as SocialNetworkProps[]
    ),
    // businessHours: BusinessHours(businessHours),
  }
}

const makeOffice: Record.Factory<IOffice> = Record(
  defaultValues,
  'OfficeRecord'
)

export const Office = (props?: IOffice): Office =>
  makeOffice(props ? mapper(props) : undefined)
export const OfficesCollection = makeList(Office)
