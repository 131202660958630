// import * as actions from './actions'
// import reducer from './reducer'
// import * as selectors from './selectors'
// import { KEY } from './constants'
import container from './container'

export * from './actions'

// export { KEY, actions, reducer, selectors }

export default container
