import { withFormik } from 'formik'
import { get, noop } from 'lodash'
import { compose, getContext } from 'recompose'
import PropTypes from 'prop-types'

import * as Yup from 'yup'

const SubscribeFormSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
})

const withContext = getContext({
  user: PropTypes.object,
})

export default compose(
  withContext,
  withFormik({
    enableReinitialize: true,
    isInitialValid: ({ validationSchema, initialValues }) =>
      validationSchema().isValidSync(initialValues),
    validationSchema: SubscribeFormSchema,
    async handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
      try {
        await new Promise(resolve => setTimeout(resolve, 3000))
        await get(props, 'onSubmit', noop)(values)
        resetForm()
      } catch (error) {
      } finally {
        setSubmitting(false)
      }
    },
    mapPropsToValues({ user }) {
      return {
        email: get(user, 'email') || '',
      }
    },
  })
)
