import React from 'react'
import { Navbar, Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import cn from 'classnames'
import { filter } from 'lodash'

import style from './style.module.scss'

const FooterNavbar = ({ menu }) => (
  <Navbar fluid className={cn(style.navbarFooter, style.navbarCentered)}>
    <Nav>
      {filter(menu, 'link').map(({ link, name }, idx) => (
        <LinkContainer key={idx} to={link} exact>
          <NavItem>{name}</NavItem>
        </LinkContainer>
      ))}
    </Nav>
  </Navbar>
)

export default FooterNavbar
