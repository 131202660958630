import { requestsReducer } from 'redux-saga-requests'

import {
  AUTHENTICATE,
  CLEAR_TOKENS,
  SET_TOKENS,
  SET_SWITCH_USER,
} from './constants'
import { ReducerRecord } from './records'

const reducer = requestsReducer(
  {
    actionType: AUTHENTICATE,
    resetOn: [CLEAR_TOKENS],
    getDefaultData: () => new ReducerRecord(),
    getData: (state, action, config) => {
      const { token, refresh_token: refreshToken } = action.data
      return new ReducerRecord({
        token,
        refreshToken,
      })
    },
    mutations: {
      [SET_TOKENS]: {
        updateData: ({ data }, { meta }) => {
          const { token, refreshToken } = meta.payload
          return data.merge({ token, refreshToken })
        },
        local: true,
      },
      [SET_SWITCH_USER]: {
        updateData: ({ data }, { meta }) => {
          return data.merge({ switchUser: meta.payload })
        },
        local: true,
      },
    },
  }
  // createReducer({ data: new ReducerRecord() }, actionHandlers)
)
export default reducer
