import { createSelector } from 'reselect'
import makeStateSelector from 'packages/core/utils/makeStateSelector'

import { MODULE } from './constants'
import { ReducerRecord } from './records'

const stateSelector = makeStateSelector(MODULE, ReducerRecord)

export const companySelector = createSelector(stateSelector, state =>
  state.data.toJS()
)
