import { companySelector } from 'packages/core/features/company/selectors'
import { makeSelectMenu } from 'packages/core/features/menu/selectors'
// import { makeSelectUser } from '../../../../redux/app/user/selectors'

const selectMenu = makeSelectMenu()
// const selectUser = makeSelectUser()

const records = {
  company: companySelector,
  menu: selectMenu,
  // user: selectUser,
}

export default records
