import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk, faHammer } from '@fortawesome/free-solid-svg-icons'

import { FormattedWorkSchedule } from './formatted_work_schedule'
import { IWorkSchedule } from 'packages/core/records/Office/work-schedule.interface'

export const Row = styled.tr<{ isHoliday: boolean }>`
  color: ${props => (props.isHoliday ? '#a2231d' : 'inherit')};

  td {
    padding: 0.25rem 0.5rem;

    &:nth-child(1) {
      /* width: 1%; */
      width: 4rem;
      white-space: nowrap;
      text-align: right;
    }
  }
`

export type ScheduleTableProps = React.HTMLAttributes<HTMLTableElement> & {
  schedule: IWorkSchedule
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({
  schedule,
  ...props
}) => (
  <table {...props}>
    <tbody>
      <FormattedWorkSchedule items={schedule} groupByDays dayShort>
        {props => (
          <Row isHoliday={props.isHoliday}>
            <td>{props.period.join(' - ')}:</td>
            <td>
              <FontAwesomeIcon icon={props.isHoliday ? faAsterisk : faHammer} />{' '}
              {props.isHoliday
                ? 'Выходной'
                : `${props.startsAt} - ${props.endsAt}`}
            </td>
          </Row>
        )}
      </FormattedWorkSchedule>
    </tbody>
  </table>
)
