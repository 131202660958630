import React from 'react'
import cn from 'classnames'

import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from 'packages/components/bs3/Button'

import style from './style.module.scss'

export type ButtonProps = BaseButtonProps & {
  unstyled?: boolean
}

export const Button: React.FC<ButtonProps> = ({ unstyled, ...props }) => (
  <BaseButton
    className={cn({
      [style.button]: !unstyled,
    })}
    {...props}
  />
)

export default Button
