import lazyPage from '../../hoc/lazyPage'

const pages = [
  {
    path: '/policy/terms-of-use',
    slug: 'terms-of-use',
  },
  {
    path: '/policy/restrictions-of-use',
    slug: 'restrictions-of-use',
  },
  {
    path: '/policy/processing-personal-data',
    slug: 'processing-personal-data',
  },
  {
    path: '/policy/privacy-policy',
    slug: 'privacy-policy',
  },
  {
    path: '/oferta',
    slug: 'oferta',
  },
  {
    path: '/obmen_vozvrat_tovara',
    slug: 'obmen_vozvrat_tovara',
  },
  {
    path: '/dostavka',
    slug: 'dostavka',
  },
  {
    path: '/kak_oplatit_zazkaz',
    slug: 'kak_oplatit_zazkaz',
  },
  // {
  //   path: '/service',
  //   slug: 'service',
  // },
]

export default () =>
  pages.map(page => ({
    ...page,
    component: lazyPage(() => import('../../containers/StaticPage')),
    exact: true,
  }))
