import { parse, formatWithOptions } from 'date-fns/fp'
import { capitalize, flow } from 'lodash/fp'
import { ru } from 'date-fns/locale'

import {
  IWorkSchedule,
  WorkDayName,
} from 'packages/core/records/Office/work-schedule.interface'

const days = Object.keys(WorkDayName) as WorkDayName[]

type MapWorkScheduleOptions = {
  groupByDays: boolean
}

export const mapWorkSchedule = (
  workSchedule: IWorkSchedule,
  options: MapWorkScheduleOptions
) =>
  days.reduce(
    (acc, dayName) => {
      const current = workSchedule[dayName]

      const key = current ? `${current.startsAt}-${current.endsAt}` : null

      const prev = acc[acc.length - 1]
      if (options.groupByDays && prev?.key === key) {
        prev.period.push(dayName)
        return acc
      }

      return acc.concat({
        key,
        period: [dayName],
        isHoliday: !current,
        startsAt: current?.startsAt,
        endsAt: current?.endsAt,
      })
    },
    [] as {
      key: string | null
      period: string[]
      isHoliday: boolean
      startsAt: string | undefined
      endsAt: string | undefined
    }[]
  )

export const formatDayName = (short: boolean = false) =>
  flow(
    parse(new Date(), 'ccc'),
    formatWithOptions({ locale: ru }, short ? 'cccccc' : 'cccc'),
    capitalize
  )
