import { memo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { compose, mapProps, setPropTypes } from 'recompose'

import Image from './Image'

export default compose(
  setPropTypes({
    circle: PropTypes.bool,
    rounded: PropTypes.bool,
    thumbnail: PropTypes.bool,
    responsive: PropTypes.bool,
    src: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    lazy: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  }),
  mapProps(
    ({
      className,
      circle = false,
      rounded = false,
      thumbnail = false,
      responsive = false,
      ...props
    }) => {
      return {
        ...props,
        className: cn(className, {
          'img-circle': circle,
          'img-rounded': rounded,
          'img-thumbnail': thumbnail,
          'img-responsive': responsive,
        }),
      }
    }
  ),
  memo
)(Image)
