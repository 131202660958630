export const KEY = 'app/cart'
export const FETCH_CART = `${KEY}`
export const ADD_TO_CART = `${KEY}-add`
export const FETCH_OFFERS = `${KEY}-offers-fetch`
export const CLEAR_OFFERS = `${KEY}-offers-clear`
// OLD ===============
// export const POPULATE_CART = `${KEY}-populate`
export const CLEAR_CART = `${KEY}-clear`
// export const REMOVE_FROM_CART = `${KEY}-remove-from-cart`
// export const CREATE_CART_ITEM = `${KEY}-create-cart-item`
export const UPDATE_CART_ITEM = `${KEY}-update-cart-item`
export const REMOVE_CART_ITEM = `${KEY}-remove-cart-item`
export const CREATE_ORDER = `${KEY}-order-create`
