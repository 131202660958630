import React from 'react'
import cn from 'classnames'

import LoginForm from './LoginForm'
import ResetPasswordForm from './ResetPasswordForm'
import style from './style.module.scss'

export const LoginWindow = ({
  handleHide = undefined,
  className: containerClassName = undefined,
}) => {
  const [isReset, setReset] = React.useState(false)
  const className = cn(style.container, containerClassName)

  return isReset ? (
    <ResetPasswordForm
      className={className}
      handleHide={handleHide}
      onSuccess={handleHide}
      handleLogin={setReset.bind(null, false)}
    />
  ) : (
    <LoginForm
      className={className}
      handleHide={handleHide}
      handleResetPassword={setReset.bind(null, true)}
    />
  )
}
