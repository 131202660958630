import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { LastLocationProvider } from 'react-router-last-location'
import 'moment/locale/ru'

import configureStore from 'packages/core/store/configureStore'
import { debug } from 'packages/core/config'

import App from 'packages/core/containers/App'
import routes from 'packages/core/routes'
import history from 'packages/core/utils/history'
import * as serviceWorker from './serviceWorker'

if (debug) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

// if (debug) {
//   const { whyDidYouUpdate } = require('why-did-you-update')
//   whyDidYouUpdate(React)
// }

const store = configureStore({}, history)

const Main = () => (
  <ReduxProvider store={store}>
    <ConnectedRouter history={history}>
      <LastLocationProvider>
        <App routes={routes(store)} locale="ru" />
      </LastLocationProvider>
    </ConnectedRouter>
  </ReduxProvider>
)

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// debug ? serviceWorker.unregister() : serviceWorker.register()
serviceWorker.unregister()
