import React from 'react'

import { formatDayName, mapWorkSchedule } from './utils'
import { IWorkSchedule } from 'packages/core/records/Office/work-schedule.interface'

type FormattedWorkScheduleProps = {
  items: IWorkSchedule
  groupByDays?: boolean
  dayShort?: boolean
  children(props: {
    isHoliday: boolean
    startsAt?: string
    endsAt?: string
    period: string[]
  }): React.ReactNode
}

export const FormattedWorkSchedule: React.FC<FormattedWorkScheduleProps> = ({
  items,
  groupByDays = false,
  dayShort = false,
  children,
}) => {
  const groups = mapWorkSchedule(items, { groupByDays })

  return (
    <>
      {groups.map(({ key, period, ...rest }, idx) => (
        <React.Fragment key={idx}>
          {children({
            period: period
              .filter(
                (v, k, all) => k === 0 || (k === all.length - 1 && all[0] !== v)
              )
              .map(formatDayName(dayShort)),
            ...rest,
          })}
        </React.Fragment>
      ))}
    </>
  )
}
