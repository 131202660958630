import React from 'react'
import { get, isFunction } from 'lodash'
import invariant from 'invariant'

const UserButton = ({
  login,
  logout,
  guestComponent,
  userComponent,
  user: { data: user, loading },
  ...props
}) => {
  const isGuest = get(user, 'isGuest', true)

  invariant(
    isFunction(login),
    'Login handler is not passed or not valid handler'
  )

  invariant(
    isFunction(logout),
    'Logout handler is not passed or not valid handler'
  )

  invariant(
    React.isValidElement(guestComponent) || isFunction(guestComponent),
    '«guestComponent» is not passed or is not valid React component'
  )

  invariant(
    React.isValidElement(userComponent) || isFunction(userComponent),
    '«userComponent» is not passed or is not valid React component'
  )

  const currentComponentProps = isGuest
    ? { login, loading }
    : { loading, logout, user }

  const CurrentComponent = isGuest ? guestComponent : userComponent

  return React.isValidElement(CurrentComponent)
    ? React.cloneElement(CurrentComponent, currentComponentProps)
    : CurrentComponent(currentComponentProps)
}

export default UserButton
