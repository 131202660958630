// import { FormattedMessage } from "react-intl"
import { withFormik } from 'formik'
import { get, noop } from 'lodash'
import { compose, getContext } from 'recompose'
import PropTypes from 'prop-types'

import * as Yup from 'yup'

const ContactFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .label('ФИО')
    .min(2)
    .max(50)
    .required(),
  phone: Yup.string()
    .label('Телефон')
    .min(2)
    .max(50)
    .required(),
  message: Yup.string()
    .label('Сообщение')
    .min(2)
    .max(50)
    .required(),
})

const withContext = getContext({
  user: PropTypes.object,
})

export default compose(
  withContext,
  withFormik({
    enableReinitialize: true,
    validationSchema: ContactFormSchema,
    async handleSubmit(
      values,
      { resetForm, setErrors, setSubmitting, props, ...rest }
    ) {
      try {
        await new Promise(resolve => setTimeout(resolve, 3000))
        await get(props, 'onSubmit', noop)(values)
        resetForm()
      } catch (error) {
      } finally {
        setSubmitting(false)
      }
    },
    mapPropsToValues({ user }) {
      return {
        fullName: get(user, 'fullName') || '',
        phone: get(user, 'phone') || '',
        message: '',
      }
    },
  })
)
