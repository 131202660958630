import React from 'react'
import cn from 'classnames'
import FontAwesome from 'react-fontawesome'
import { find } from 'lodash'

import style from './style.module.scss'

const HeaderPhone = ({ className, company }) => {
  const mainPhone = find(company.phones, 'isMain')
  return (
    mainPhone && (
      <div className={style.headerPhoneBox}>
        <div className={cn(style.headerPhone, className)}>
          <FontAwesome
            name="phone-square"
            className={cn(style.icon, 'visible-xs-inline-block')}
          />
          <FontAwesome name="phone" className={cn(style.icon, 'hidden-xs')} />
          <a href={`tel:${mainPhone.number}`} className={style.number}>
            {mainPhone.number}
          </a>
          <span className={style.text}>Позвонить нам</span>
        </div>
      </div>
    )
  )
}

export default HeaderPhone
