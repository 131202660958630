import { EntityRecord, ListRecord } from '../utils/CollectionRecord'

const MenuItemRecord = EntityRecord(
  {
    name: null,
    link: null,
    exact: null,
    menu: null,
  },
  ({ menu }) => ({
    menu: menu ? MenuRecord(menu) : null,
  })
)

const MenuRecord = ListRecord(MenuItemRecord)

export default MenuRecord
