import React from 'react'
import cn from 'classnames'

import { Grid, Clearfix } from 'react-bootstrap'

// import TopToolbar from './top-toolbar'
import Logo from './Logo'
import Navigation from './Navigation'
import BottomBlock from './Bottom'
import HeaderPhone from './HeaderPhone'

import style from './style.module.scss'

export type HeaderProps = React.PropsWithChildren<{
  navClassName?: string
  headerPhoneClassName?: string
  bottomClassName?: string
}> &
  React.HTMLProps<any>

export const Header: React.FC<HeaderProps> = ({
  className,
  navClassName,
  headerPhoneClassName,
  bottomClassName,
  children,
}) => (
  <header className={cn(style.header, className)}>
    <div className={style.headerBottomDecor} />
    {children}
    <Grid>
      <div className={style.headerRow}>
        <Logo />
        <Navigation className={navClassName} />
        <HeaderPhone className={headerPhoneClassName} />
        <Clearfix visibleMdBlock visibleLgBlock />
        <BottomBlock className={bottomClassName} />
      </div>
    </Grid>
  </header>
)
