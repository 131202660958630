import { EntityRecord } from 'packages/core/utils/CollectionRecord'

// import { GoodRecord } from 'core/records'

export const ReducerRecord = EntityRecord(
  {
    query: '',
    vin: null,
    // current: null,
    // entities: GoodRecord.Collection(),
  } /*,
  ({ current = undefined, entities }) => ({
    current: current ? GoodRecord(current) : null,
    entities: entities ? GoodRecord.Collection(entities) : undefined,
  })*/
)
