import React from 'react'
// import { compose, withState, withHandlers } from "recompose"
import { Button, Modal, Col, Grid } from 'react-bootstrap'
// import FontAwesome from "react-fontawesome"

// import style from "./style.module.scss"
// https://wheatandcat.github.io/react-loading-progress/?knob-fadeOut=true&knob-loading=false&knob-error=false&selectedKind=Loading&selectedStory=Loading%20with%20error&full=0&addons=1&stories=1&panelRight=1&addonPanel=storybooks%2Fstorybook-addon-knobs
import { compose, withState, withHandlers, renameProp } from 'recompose'
import { noop } from 'lodash'

const ModalButton = props => {
  const {
    title,
    show,
    handleShow,
    handleHide,
    children,
    component,
    render,
    bsSize,
  } = props

  return (
    <>
      {React.cloneElement(children, { onClick: handleShow })}
      <Modal bsSize={bsSize} show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid style={{ padding: 0 }}>
            {render
              ? render({ closeModal: handleHide })
              : React.cloneElement(component, { closeModal: handleHide })}
          </Grid>
        </Modal.Body>
        {false && (
          <Modal.Footer>
            <Col xs={12} className="text-center">
              <Button bsStyle="danger" onClick={handleHide}>
                Закрыть
              </Button>
            </Col>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

const enhancer = compose(
  renameProp('show', 'initialShow'),
  withState('show', 'setShow', ({ initialShow = false }) => initialShow),
  withHandlers({
    handleShow: props => () => {
      const { setShow, beforeShow = noop, afterShow = noop } = props
      beforeShow(props)
      setShow(true)
      afterShow(props)
    },
    handleHide: props => () => {
      const { setShow, beforeHide = noop, afterHide = noop } = props
      beforeHide(props)
      setShow(false)
      afterHide(props)
    },
  })
)

export default enhancer(ModalButton)
