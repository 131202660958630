import React from 'react'
import Combobox from 'react-widgets/lib/Combobox'
import FontAwesome from 'react-fontawesome'
import size from 'lodash/size'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'

import ListItem from './ListItem'
import style from './style.module.scss'

const SearchField = ({
  handleSelect,
  handleChange,
  data,
  loading,
  query,
  ...props
}) => {
  const messages = {
    emptyList: ({ searchTerm }) => {
      return size(searchTerm) < 2 ? (
        <span>Введите номер запчасти(не менее 2-х символов)</span>
      ) : loading ? (
        <span className="text-warning">{`Ищем «${searchTerm}» ...`}</span>
      ) : (
        <span className="text-danger">{`По запросу «${searchTerm}» ничего не найдено`}</span>
      )
    },
    emptyFilter: 'emptyFilter',
    filterPlaceholder: 'filterPlaceholder',
  }

  return (
    <span {...props} className={cn(style.container, props.className)}>
      <Combobox
        // open
        // className={style.container}
        data={data}
        value={query}
        onChange={handleChange}
        onSelect={handleSelect}
        placeholder="Номер запчасти...."
        itemComponent={ListItem}
        busy={loading}
        selectIcon={<FontAwesome name="search" size="lg" />}
        filter={false}
        // suggest
        textField="number"
        autoFocus={!isMobile}
        // onFocus={handleFocus}
        messages={messages}
      />
    </span>
  )
}

export default SearchField
