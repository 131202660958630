import { createSelector } from 'reselect'
import makeStateSelector from 'packages/core/utils/makeStateSelector'

import { MODULE } from './constants'
import { ReducerRecord } from './records'

const stateSelector = makeStateSelector(MODULE, ReducerRecord)

export const makeSelectMenu = () =>
  createSelector(stateSelector, state => state.data.valueSeq().toJS())
