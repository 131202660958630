import { FormControl } from 'react-bootstrap'
import { injectIntl, FormattedMessage } from 'react-intl'
import { noop, get, isEmpty } from 'lodash'
import { compose, mapProps, setDisplayName, withProps } from 'recompose'
import { Checkbox } from 'react-bootstrap'
import NumericInput from 'react-numeric-input'
import Combobox from 'react-widgets/lib/Combobox'
import DropdownList from 'react-widgets/lib/DropdownList'

// import React from 'react'
// import { useField } from 'formik'

export default compose(
  injectIntl,
  mapProps(({ field, form = {}, placeholder, intl, ...props }) => {
    const { onChange = noop, name, value, ...fieldProps } = isEmpty(field)
      ? props
      : field
    const valueProps =
      props.componentClass === Checkbox ? { checked: value } : { value }

    return {
      ...props,
      ...fieldProps,
      ...valueProps,
      name,
      onChange: onChange(name),
      placeholder:
        get(placeholder, 'type') === FormattedMessage
          ? intl.formatMessage(placeholder.props)
          : placeholder,
    }
  }),
  withProps(({ componentClass, onChange }) => {
    if (componentClass === NumericInput) {
      return {
        onChange: (valueAsNumber, valueAsString, event) =>
          onChange(valueAsString),
        // onChange({ target: event }),
      }
    }
  }),
  withProps(({ componentClass, onChange }) => {
    if (componentClass === Combobox) {
      return {
        onChange: (valueAsNumber, valueAsString) => onChange(valueAsString),
      }
    }
  }),
  withProps(({ componentClass, onChange }) => {
    if (componentClass === DropdownList) {
      return {
        onChange: (dataItems, { originalEvent }) =>
          onChange(String(dataItems.id)),
      }
    }
  }),
  setDisplayName('FormikFormControl')
)(FormControl)
