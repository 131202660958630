import React from 'react'
import FontAwesome from 'react-fontawesome'
import { MenuItem as BaseMenuItem } from 'react-bootstrap'
import { uniqueId } from 'lodash'
import { LinkContainer } from 'react-router-bootstrap'
import ConditionalWrap from 'conditional-wrap'

const MenuItem = ({
  children,
  icon = '',
  link,
  isRemote = false,
  ...props
}) => (
  <ConditionalWrap
    condition={!!link && !isRemote}
    wrap={children => <LinkContainer to={link}>{children}</LinkContainer>}
  >
    <BaseMenuItem
      eventKey={uniqueId()}
      {...props}
      {...(isRemote && { href: link, target: '_blank' })}
    >
      {props.divider ? (
        undefined
      ) : (
        <>
          <FontAwesome name={icon} fixedWidth />
          &nbsp;
          {children}
        </>
      )}
    </BaseMenuItem>
  </ConditionalWrap>
)

export default MenuItem
