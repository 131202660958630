import React from 'react'
import { Nav, NavItem } from 'react-bootstrap'

import Link from 'packages/components/common/Link'

import DropdownItem from './DropdownItem'

const Menu = ({ menu }) => (
  <Nav>
    {menu.map((item, idx) =>
      item.menu ? (
        <DropdownItem key={idx} item={item} eventKey={idx} />
      ) : (
        <NavItem
          key={idx}
          eventKey={idx}
          componentClass={Link}
          href={item.link}
        >
          {item.name}
        </NavItem>
      )
    )}
  </Nav>
)

export default Menu
