import { combineReducers } from 'redux'

import { reducer as authReducer } from './auth'
import { reducer as companyReducer, KEY as companyKEY } from './company'
import { reducer as menuReducer, KEY as menuKEY } from './menu'
import {
  reducer as searchFieldReducer,
  KEY as searchFieldKEY,
} from './searchField'

const reducers = {
  auth: authReducer,
  [companyKEY]: companyReducer,
  [menuKEY]: menuReducer,
  [searchFieldKEY]: searchFieldReducer,
}

// export default reducers
export default { core: combineReducers(reducers) }
