import {
  AUTHENTICATE,
  INITIALIZE,
  SET_TOKENS,
  CLEAR_TOKENS,
  SET_SWITCH_USER,
  LOGOUT,
} from './constants'

export function initialize() {
  return {
    type: INITIALIZE,
  }
}

export function setTokens(tokens) {
  return {
    type: SET_TOKENS,
    meta: {
      payload: tokens,
    },
  }
}

export function clearTokens() {
  return {
    type: CLEAR_TOKENS,
  }
}

export function authenticate(credientals) {
  return {
    type: AUTHENTICATE,
    request: {
      url: '/auth/login',
      method: 'POST',
      data: credientals,
    },
    meta: {
      asPromise: true,
      // abortOn     : LOGOUT,
    },
  }
}

export function switchUser(user) {
  return {
    type: SET_SWITCH_USER,
    meta: {
      payload: user,
    },
  }
}

export function logout() {
  return {
    type: LOGOUT,
  }
}
