import React from 'react'
import cn from 'classnames'
import { Navbar } from 'react-bootstrap'

import Menu from './Menu'

import style from './style.module.scss'

const Navigation = ({ className, menu }) => (
  <div className={cn(style.headerNavbarBox, className)}>
    <Navbar fluid collapseOnSelect className={style.headerNavbar}>
      <div className={style.headerNavbarToggleBox}>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Menu menu={menu} />
      </Navbar.Collapse>
    </Navbar>
  </div>
)

export default Navigation
