import { Record, RecordOf } from 'immutable'

export type VendorProps = {
  id?: number
  name?: string
}

export type Vendor = RecordOf<VendorProps>

const defaultValues: VendorProps = {
  id: undefined,
  name: undefined,
}

const makeVendor: Record.Factory<VendorProps> = Record(
  defaultValues,
  'VendorRecord'
)

export const Vendor = <T>(props: VendorProps): Vendor => makeVendor(props)
