import { SET_QUERY, SET_CURRENT, FETCH_GOODS, CLEAR_GOODS } from './constants'
// import { GoodRecord } from 'core/records-old'
import { GoodsCollection } from 'packages/core/records'

export function setQuery(query, { debounce = 500, minLength = 2 } = {}) {
  return {
    type: SET_QUERY,
    payload: query,
    meta: {
      debounce,
      minLength,
    },
  }
}

export function selectGood(current) {
  return {
    type: SET_CURRENT,
    payload: current,
  }
}

export function fetchGoods(query) {
  return {
    type: FETCH_GOODS,
    request: {
      url: '/search/goods',
      params: {
        number: query,
      },
    },
    meta: {
      // asPromise: true,
      resetOn: [CLEAR_GOODS],
      takeLatest: true,
      cache: 600,
      cacheKey: query,
      getData: (state, action) => GoodsCollection(action.data),
    },
  }
}

export function clearGoods() {
  return {
    type: CLEAR_GOODS,
  }
}
