import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { Form } from 'formik'

import {
  Col,
  Form as BootstrapForm,
  FormGroup,
  ControlLabel,
} from 'react-bootstrap'
import { Field } from 'formik'

import { FormControl } from 'packages/components/bs3/Formik'

import { Button, SubmitButton } from 'app/components/Button'

const LoginModal = ({
  handleHide,
  handleResetPassword,
  isSubmitting,
  className,
}) => (
  <Form className={className}>
    <Modal.Header closeButton={!!handleHide}>
      <Modal.Title>
        <FormattedMessage id="form.login.title" />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <BootstrapForm horizontal componentClass="div">
        <FormGroup controlId="EmailField">
          <Col componentClass={ControlLabel} sm={2}>
            Email
          </Col>
          <Col sm={10}>
            <Field
              name="username"
              type="email"
              placeholder="Email"
              component={FormControl}
              readOnly={isSubmitting}
            />
          </Col>
        </FormGroup>
        <FormGroup controlId="PasswordField">
          <Col componentClass={ControlLabel} sm={2}>
            Пароль
          </Col>
          <Col sm={10}>
            <Field
              name="password"
              type="password"
              placeholder="Пароль"
              component={FormControl}
              readOnly={isSubmitting}
            />
          </Col>
        </FormGroup>
      </BootstrapForm>
      <div className="text-right tex-muted">
        <Button bsStyle="link" onClick={handleResetPassword}>
          Забыли пароль?
        </Button>
      </div>
    </Modal.Body>
    <Modal.Footer className="text-center">
      <Button to="/registration" onClick={handleHide}>
        Зарегистрироваться
      </Button>{' '}
      <SubmitButton type="submit" bsStyle="info" icon="sign-in">
        <FormattedMessage id="form.login.button" />
      </SubmitButton>
    </Modal.Footer>
  </Form>
)

export default LoginModal
