import { createElement } from 'react'
// import { Link as BaseLink } from 'react-router-dom'
import { HashLink as BaseLink } from 'react-router-hash-link'
import { includes, isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'

import isExternalLink from 'packages/core/helpers/isExternalLink'

const types = {
  email: to => `mailto:${to}`,
  tel: to => `tel:${to}`,
  whatsapp: to =>
    isMobile ? `whatsapp://send?text=&phone=${to}&abid=${to}` : undefined,
}

const Link = ({ to = '', href = '', type = '', ...props }) => {
  const url = type && types[type] ? types[type](href || to) : href || to

  const isExternal = isExternalLink(url)

  if (isEmpty(url)) {
    return props.children
  }

  return createElement(isExternal ? 'a' : BaseLink, {
    ...(isExternal
      ? { href: url, target: 'blank', rel: 'nofollow noopener' }
      : { to: url, smooth: includes(url, '#') }),
    ...props,
  })
}

export default Link
