import { includes, map, pick } from 'lodash'
import { success } from 'redux-saga-requests'

import {
  // CLEAR_CART,
  // POPULATE_CART,
  // CREATE_CART_ITEM,
  ADD_TO_CART,
  UPDATE_CART_ITEM,
  REMOVE_CART_ITEM,
  FETCH_CART,
  FETCH_OFFERS,
  CLEAR_OFFERS,
  CREATE_ORDER,
} from './constants'
// import { CartItemRecord } from 'core/records-old'
import {
  CartItem,
  CartItemsCollection,
  OffersCollection,
} from 'packages/core/records'

import { FETCH_USER } from '../user/constants'

export function fetchCart() {
  return {
    type: FETCH_CART,
    request: {
      url: '/v2/cart',
    },
    meta: {
      getData: (state, action) => CartItemsCollection(action.data),
    },
  }
}

export function fetchCartOffers() {
  return {
    type: FETCH_OFFERS,
    request: {
      url: '/cart/offers',
    },
    meta: {
      resetOn: [CLEAR_OFFERS],
      getData: (state, action) => OffersCollection(action.data),
    },
  }
}
export function clearCartOffers() {
  return {
    type: CLEAR_OFFERS,
  }
}

export function addToCart(offer, { amount, contract, reference } = {}) {
  return {
    type: ADD_TO_CART,
    request: {
      url: '/cart/add',
      params: {
        itemKey: offer.itemKey,
        amount,
        reference,
        contractId: contract.id,
      },
    },
    meta: {
      offer,
      amount,
      contract,
      reference,
      asPromise: true,
      // asMutation: true,
      mutations: {
        getRequestKey: requestAction =>
          String(requestAction.meta.offer.itemKey),
        [FETCH_USER]: {
          updateData: (
            state,
            { meta: { contract, offer, vendor, reference }, data }
          ) => {
            const createKey = ({ itemKey, contract, reference }) =>
              [itemKey, contract.id, reference].filter(Boolean).join('|')

            const cartItem = CartItem(data)

            return state.data.update('cart', cart =>
              cart
                .toMap()
                .mapKeys((key, item) => createKey(item))
                // .mergeWith(
                //   (oldItem, newItem) =>
                //     oldItem.update('amount', amount => amount + newItem.amount),
                //   [[createKey(cartItem), cartItem]]
                // )
                .merge([[createKey(cartItem), cartItem]])
                .toList()
            )
          },
        },
        [FETCH_CART]: {
          updateData: (state, { data, type }) => {
            if (type === success(ADD_TO_CART)) {
              const createKey = ({ good, vendor, contract, reference }) =>
                [good.id, vendor.id, contract.id, reference]
                  .filter(Boolean)
                  .join('|')

              const cartItem = CartItem(data)

              return state.data
                .toMap()
                .mapKeys((key, item) => createKey(item))
                .update(createKey(cartItem), () => cartItem)
                .toList()
            }
            return state.data
          },
        },
      },
    },
  }
}

// OLD ===================================

const addCart = (cart, data) => cart.push(CartItem(data))

const updateCart = (cart, cartItemId, changes) =>
  cart
    .toMap()
    .mapKeys((key, item) => item.id)
    .update(cartItemId, item => item.merge(changes))
    .toList()

const removeCart = (cart, cartItemId) =>
  cart
    .toMap()
    .mapKeys((key, item) => item.id)
    .remove(cartItemId)
    .toList()

export function updateCartItem(cartItem, changes) {
  return {
    type: UPDATE_CART_ITEM,
    request: {
      url: `/cart/update/${cartItem.id}`,
      method: 'post',
      data: changes,
    },
    meta: {
      cartItem,
      changes,
      asPromise: true,
      takeLatest: true,
      mutations: {
        getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
        [FETCH_USER]: {
          updateDataOptimistic: ({ data }, { meta: { cartItem, changes } }) => {
            return data.update('cart', cart =>
              updateCart(cart, cartItem.id, changes)
            )
          },
          revertData: ({ data }, { meta: { cartItem } }) => {
            return data.update('cart', cart =>
              updateCart(cart, cartItem.id, cartItem)
            )
          },
        },
        [FETCH_CART]: {
          updateDataOptimistic: ({ data }, { meta: { cartItem, changes } }) => {
            return data
              .toMap()
              .mapKeys((key, item) => item.id)
              .update(cartItem.id, item => item.merge(changes))
              .toList()
          },
          revertData: ({ data }, { meta: { cartItem } }) => {
            return data
              .toMap()
              .mapKeys((key, item) => item.id)
              .update(cartItem.id, item => item.merge(changes))
              .toList()
          },
        },
      },
    },
  }
}

export function removeCartItem(cartItem) {
  return {
    type: REMOVE_CART_ITEM,
    request: {
      url: `/cart/remove/${cartItem.id}`,
    },
    meta: {
      cartItem,
      asPromise: true,
      mutations: {
        getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
        [FETCH_USER]: {
          updateDataOptimistic: ({ data }, { meta: { cartItem, changes } }) => {
            return data.update('cart', cart => removeCart(cart, cartItem.id))
          },
          revertData: ({ data }, { meta: { cartItem } }) => {
            return data.update('cart', cart => addCart(cart, cartItem))
          },
        },
        [FETCH_CART]: {
          updateDataOptimistic: ({ data }, { meta: { cartItem, changes } }) => {
            return cartItem ? removeCart(data, cartItem.id) : data
          },
          revertData: ({ data }, { meta: { cartItem } }) => {
            return cartItem ? addCart(data, cartItem) : data
          },
        },
      },
    },
  }
}

export function createOrder({
  cartItems,
  deliveryType,
  paymentMethod,
  ...props
}) {
  return {
    type: CREATE_ORDER,
    request: {
      url: '/orders/create',
      method: 'post',
      data: {
        ...props,
        contractId: cartItems[0].contract.id,
        deliveryType: { id: '001' },
        paymentMethod: { id: paymentMethod },
        items: cartItems.map(item => pick(item, ['id'])),
      },
    },
    meta: {
      cartItems,
      asPromise: true,
      mutations: {
        // getRequestKey: requestAction => String(requestAction.meta.cartItem.id),
        [FETCH_USER]: {
          updateDataOptimistic: (
            { data },
            { meta: { cartItems, changes } }
          ) => {
            const idList = map(cartItems, 'id')

            return data.update('cart', cart =>
              cart.filterNot(cartItem => includes(idList, cartItem.id))
            )
          },
          revertData: ({ data }, { meta: { cartItem } }) => {
            return data.update('cart', cart => addCart(cart, cartItem))
          },
          // local: true,
        },
      },
    },
  }
}
