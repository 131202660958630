import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { setQuery, selectGood } from './actions'
import { querySelector, selectSuggest } from './selectors'

const mapStateToProps = state =>
  createStructuredSelector({
    query: selectSuggest,
    result: querySelector,
  })

const mapDispatchToProps = {
  handleChange: setQuery,
  handleSelect: selectGood,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withProps = mapProps(({ result, ...props }) => ({
  ...result,
  ...props,
}))

export default compose(withConnect, withProps)
