import React from 'react'
import ReactImage from 'react-image'
import LazyLoad from 'react-lazyload'
import ConditionalWrap from 'conditional-wrap'
import { isObject } from 'lodash'

import Loader from './Loader'

const Image = ({ loader = true, lazy = true, tooltip, children, ...props }) => (
  <ConditionalWrap
    condition={!!lazy}
    wrap={children => (
      <LazyLoad offset={100} {...(isObject(lazy) && lazy)}>
        {children}
      </LazyLoad>
    )}
  >
    <ReactImage
      {...props}
      loader={
        loader ? (
          <Loader style={props.style} className={props.className} />
        ) : (
          false
        )
      }
      container={children => {
        return children
      }}
    />
    {children}
  </ConditionalWrap>
)

export default Image
