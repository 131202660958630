import React from 'react'
import cn from 'classnames'
import FontAwesome from 'react-fontawesome'

import { Button } from '..'
import style from './style.module.scss'

const CompositeButton = ({ children, icon, className, ...props }) => (
  <Button {...props} className={cn(style.buttonComposite, className)}>
    <span className={style.buttonCompositeBody}>{children}</span>
    <FontAwesome name={icon} className={style.buttonCompositeIcon} />
  </Button>
)

export default CompositeButton
