import { mapProps } from 'recompose'
import {
  ErrorBoundary as BaseErrorBoundary,
  withErrorBoundary as baseWithErrorBoundary,
} from 'react-error-boundary'

import ClientError from 'app/view/Errors/ClientError'

const ErrorBoundary = mapProps(props => ({
  ...props,
  FallbackComponent: ClientError,
}))(BaseErrorBoundary)

export default ErrorBoundary

export const withErrorBoundary = ComponentThatMayError =>
  baseWithErrorBoundary(
    ComponentThatMayError,
    ClientError,
    (error, componentStack) => {
      console.error(error)
    }
  )
