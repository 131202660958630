import { Map } from 'immutable'

import { ReducerRecord } from './records'
import { GoodsCollection } from 'packages/core/records'

import { FETCH_CAROUSEL } from './constants'

export function fetchCarouselAction(name: string) {
  return {
    type: FETCH_CAROUSEL,
    request: {
      url: `/carousel/${name}`,
    },
    meta: {
      name,
      getDefaultData: (multi: boolean) => Map(),
      getData: (state: any, { data: result, meta: { name } }: any) => {
        const data = state.data || ReducerRecord
        try {
          return data.set(name, GoodsCollection(result))
        } catch (error) {
          return data
        }
      },
    },
  }
}
