const initialState = {
  name: 'SM-AVTO',
  address: {
    country: 'РФ',
    // region: 'Московская область',
    city: 'г. Москва',
    street: 'ул. Ташкентская',
    building: 'д. 28, стр. 1',
    additional: '(территория 19 ТМП) 2 этаж',
  },
  phones: [
    {
      number: '+7 966 142-29-51',
      name: '',
      isMain: true,
    },
    {
      number: '+7 966 142-29-52',
      name: '',
    },
  ],
  socialNetworks: [
    // {
    //   name: 'VK',
    //   icon: 'vk',
    //   link: 'https://vk.com/',
    // },
    // {
    //   name: 'Instagram',
    //   icon: 'instagram',
    //   link: 'https://www.instagram.com/',
    // },
  ],
  messengers: {
    whatsApp: ['+7 966 142-29-51'],
  },
  email: 'sm-avto@bk.ru',
  businessHours: [
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['10:00', '17:00'],
    ['10:00', '17:00'],
  ],
  map: {
    center: [55.699593, 37.80644],
    zoom: 17,
    link: 'https://yandex.ru/maps/-/CGhezK8g',
  },
}

export const initialState2 = {
  name: 'SM-AVTO',
  address: {
    country: 'РФ',
    // region: 'Московская область',
    city: 'г. Москва',
    street: 'ул. Ташкентская',
    building: 'д. 28, стр. 1',
    additional: '(территория 19 ТМП) 2 этаж',
  },
  phones: [
    {
      number: '+7 966 142-29-51',
      name: '',
      isMain: true,
    },
    {
      number: '+7 966 142-29-52',
      name: '',
    },
  ],
  socialNetworks: [
    // {
    //   name: 'VK',
    //   icon: 'vk',
    //   link: 'https://vk.com/',
    // },
    // {
    //   name: 'Instagram',
    //   icon: 'instagram',
    //   link: 'https://www.instagram.com/',
    // },
  ],
  messengers: {
    whatsApp: ['+7 966 142-29-51'],
  },
  email: 'sm-avto@bk.ru',
  businessHours: [
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['9:00', '19:00'],
    ['10:00', '17:00'],
    ['10:00', '17:00'],
  ],
  map: {
    center: [55.699593, 37.80644],
    zoom: 17,
    link: 'https://yandex.ru/maps/-/CGhezK8g',
  },
  offices: [
    {
      id: 1,
      name: 'Магазин',
      address: {
        country: 'РФ',
        // region: 'Московская область',
        city: 'г. Москва',
        street: 'ул. Ташкентская',
        building: 'д. 28, стр. 1',
        additional: '(территория 19 ТМП) 2 этаж',
      },
      isSalesPoint: true,
      phones: [
        {
          number: '+7 966 142-29-51',
          name: '',
          isMain: true,
        },
        {
          number: '+7 966 142-29-52',
          name: '',
        },
        {
          number: '+7 966 151-57-37',
          name: '',
        },
      ],
      messengers: {
        whatsApp: ['+7 966 142-29-51'],
      },
      email: 'sm-avto@bk.ru',
      businessHours: {
        Monday: {
          start: 9,
          end: 19,
        },
        Tuesday: {
          start: 9,
          end: 19,
        },
        Wednesday: {
          start: 9,
          end: 19,
        },
        Thursday: {
          start: 9,
          end: 19,
        },
        Friday: {
          start: 9,
          end: 19,
        },
        Saturday: {
          start: 10,
          end: 17,
        },
        Sunday: {
          start: 10,
          end: 17,
        },
      },
      map: {
        center: [55.699593, 37.80644],
        zoom: 17,
        link: 'https://yandex.ru/maps/-/CGhezK8g',
      },
    },
    // {
    //   id: 2,
    //   name: 'Магазин',
    //   address: {
    //     country: 'РФ',
    //     region: 'МО',
    //     city: 'г. Бронницы',
    //     street: 'Кирпичный проезд',
    //     building: 'д. 2',
    //   },
    //   isSalesPoint: true,
    //   phones: [
    //     {
    //       number: '+7 966 151-57-37',
    //       name: '',
    //       isMain: true,
    //     },
    //   ],
    //   email: 'Sm-avto.bronnitsy@bk.ru',
    //   businessHours: {
    //     Monday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Tuesday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Wednesday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Thursday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Friday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     // Saturday: {
    //     //   start: 10,
    //     //   end: 17,
    //     // },
    //     // Sunday: {
    //     //   start: 10,
    //     //   end: 17,
    //     // },
    //   },
    //   map: {
    //     center: [55.41398, 38.280457],
    //     zoom: 17,
    //     // link: 'https://yandex.ru/maps/-/CGhezK8g',
    //   },
    // },
    // {
    //   id: 3,
    //   name: 'Автосервис',
    //   address: {
    //     country: 'РФ',
    //     region: 'МО',
    //     city: 'г. Бронницы',
    //     street: 'Кирпичный проезд',
    //     building: 'д. 2',
    //   },
    //   phones: [
    //     {
    //       number: '+7 977 940-90-96',
    //       name: '',
    //       isMain: true,
    //     },
    //   ],
    //   // messengers: {
    //   //   whatsApp: ['+7 966 142-29-51'],
    //   // },
    //   email: 'v5068396@yandex.ru',
    //   businessHours: {
    //     Monday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Tuesday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Wednesday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Thursday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Friday: {
    //       start: 9,
    //       end: 19,
    //     },
    //     Saturday: {
    //       start: 10,
    //       end: 17,
    //     },
    //     Sunday: {
    //       start: 10,
    //       end: 17,
    //     },
    //   },
    //   map: {
    //     center: [55.41398, 38.280457],
    //     zoom: 17,
    //     // link: 'https://yandex.ru/maps/-/CGhezK8g',
    //   },
    // },
  ],
}

export default initialState
