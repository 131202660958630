import { createSelector } from 'reselect'
import { filter, find, first } from 'lodash'

import { userRecordSelector } from 'packages/core/features/user/selectors'

export const contractsSelector = createSelector(
  userRecordSelector,
  ({ data: user, ...state }) => ({
    ...state,
    data: user.contracts.valueSeq().toJS(),
  })
)

export const orderContractsSelector = createSelector(
  contractsSelector,
  ({ data: contracts, ...state }) => ({
    ...state,
    data: filter(contracts, 'isForOrder'),
  })
)

export const currentContractSelector = createSelector(
  orderContractsSelector,
  ({ data: orderContracts, ...state }) => ({
    ...state,
    data: find(orderContracts, 'isCurrent') || first(orderContracts),
  })
)
