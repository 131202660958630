import {
  delay,
  call,
  put,
  putResolve,
  select,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects'
import { success } from 'redux-saga-requests'
import { replace } from 'connected-react-router'
import { get } from 'lodash'

import { LOGOUT } from '../auth/constants'
import { authenticate } from '../auth/actions'
import {
  FETCH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  RESET_PASSWORD,
} from './constants'
import { applyUserRoles, clearUser, fetchUser, login } from './actions'
import { fetchCart } from '../cart/actions'
import { userSelector } from './selectors'

function* watchLogout() {
  yield put(clearUser())
}

function* watchSuccessFetchUser() {
  yield delay(10)
  yield put(applyUserRoles())
  yield put(fetchCart())
}

function* watchLoginUser({ meta: { credientals, onSuccess, onFailure } }) {
  try {
    yield putResolve(authenticate(credientals))
    yield putResolve(fetchUser())
    yield put(fetchCart())
    const user = yield select(userSelector)
    yield call(onSuccess, user.data)
  } catch (error) {
    yield call(onFailure)
  }
}

function* watchUserRegistration({ meta: { profile } }) {
  const credientals = {
    username: profile.email,
    password: profile.plainPassword.first,
  }
  try {
    yield putResolve(login(credientals))
    yield put(replace('/'))
  } catch (error) {}
}

function* watchLogoutUser({ payload }) {}

function* watchSuccessResetPassword({ data, meta }) {
  const username = get(data, 'user.email')
  const password = get(meta, 'password')

  if (username && password) {
    const credientals = { username, password }
    yield put(login(credientals))
    yield put(replace('/'))
  }
}

export default function* saga() {
  yield takeLeading(LOGOUT, watchLogout)
  yield takeLatest(success(FETCH_USER), watchSuccessFetchUser)
  yield takeLatest(LOGIN_USER, watchLoginUser)
  yield takeLeading(LOGOUT_USER, watchLogoutUser)
  yield takeLatest(success(REGISTER_USER), watchUserRegistration)
  yield takeLatest(success(RESET_PASSWORD), watchSuccessResetPassword)
}
