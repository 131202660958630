import { Record, RecordOf } from 'immutable'
import { chain, replace } from 'lodash'

export type IMapPlace = {
  center?: {
    lon?: number
    lat?: number
  }
  url?: string
  zoom?: number
}

export type MapPlace = RecordOf<IMapPlace>

const defaultValues: IMapPlace = {
  center: undefined,
  url: undefined,
  zoom: undefined,
}

const mapper = (props: IMapPlace): IMapPlace => ({
  ...props,
})

const makeMapPlace: Record.Factory<IMapPlace> = Record(
  defaultValues,
  'MapPlaceRecord'
)

export const MapPlace = (props?: IMapPlace): MapPlace =>
  makeMapPlace(props ? mapper(props) : undefined)
