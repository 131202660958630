import React from 'react'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { uniqueId } from 'lodash'
import { IntlProvider } from 'react-intl'

const Tooltip = (
  { text, children, placement = 'top', intl, ...props },
  ...rest
) => (
  <OverlayTrigger
    {...props}
    placement={placement}
    overlay={
      <BootstrapTooltip intl={intl} id={uniqueId('tooltip-')}>
        <IntlProvider {...intl}>{text}</IntlProvider>
      </BootstrapTooltip>
    }
  >
    {children}
  </OverlayTrigger>
)

export default Tooltip
