import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { officesSelector } from 'packages/core/features/office/selectors'
import Link from 'packages/components/common/Link'
import Widget from './Widget'
import style from './style.module.scss'

const BottomBlock = ({ className }) => {
  const { data: offices = [] } = useSelector(officesSelector)

  return (
    <div className={cn(style.headerBottom, className)}>
      {offices?.length &&
        offices.map(({ address, name, id }, idx) => (
          <Widget
            key={idx}
            className={style.contacts}
            icon="map-marker"
            url="/address"
          >
            <Link to={`/contacts#office-${id}`}>{name}</Link>
            <br />
            <small>
              <Link to={`/contacts#office-${id}`}>{address.fullAddress}</Link>
            </small>
            {/* <br /> */}
            {/* <small>{address.additional}</small> */}
          </Widget>
        ))}
      {/* <WorkingTime
      className={style.workHours}
      // workingTime={company.getShortWorkingTime({ short: true })}
      businessHours={company.businessHours}
    />
    <Address className={style.location} address={company.address} />
    <Widget className={style.contacts} icon="comments-o">
      {company.phones.map(phone => (
        <div key={phone.number}>
          <FontAwesome key={phone.number} fixedWidth name="phone" />
          :&nbsp;{phone.number}
        </div>
      ))}
      {!isEmpty(company.messengers.whatsApp) && (
        <div>
          <FontAwesome fixedWidth name="whatsapp" />:{' '}
          {first(company.messengers.whatsApp)}
        </div>
      )}
    </Widget> */}
    </div>
  )
}

export default BottomBlock
