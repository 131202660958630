import React from 'react'
import { Link } from 'react-router-dom'

import style from './style.module.scss'

import Image from 'packages/components/common/Image'
import getImagineUrl from 'packages/core/helpers/getImagineUrl'

const Logo = props => (
  <div className={style.headerLogoBox}>
    <Link to="/">
      <div className={style.headerLogo}>
        <Image
          src={getImagineUrl('logo_stroke.png', 'main_header_logo')}
          alt=""
          style={{ height: 85, width: 85 }}
        >
          <span className={style.title}>Автозапчасти</span>
        </Image>
      </div>
    </Link>
  </div>
)

export default Logo
