import invariant from 'invariant'
import { compose, mapProps } from 'recompose'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { difference, fromPairs, isEmpty, keys, map, omit } from 'lodash'

import allRecords from './records'
import normalizeParams from '../normalizeParams'

const withConnect = recordNames => {
  const records = normalizeParams(recordNames)
  const badRecords = difference(keys(records), keys(allRecords))
  invariant(
    isEmpty(badRecords),
    `Record(s) [ ${badRecords.join(', ')} ] not found. Only [ ${keys(
      allRecords
    ).join(', ')} ] records available.`
  )

  return connect(
    createStructuredSelector(
      fromPairs(map(records, (mapping, name) => [mapping, allRecords[name]]))
    )
  )
}

const withRecords = (...params) =>
  compose(
    withConnect(normalizeParams(params)),
    mapProps(props => omit(props, 'dispatch'))
  )
export default withRecords
