import { List, Record, RecordOf } from 'immutable'

import { makeList } from './CollectionFactory'

export type DeliveryMethodProps = {
  id?: number
  isAddressRequired: boolean
  isDefault: boolean
  isDeliveryRouteRequired: boolean
  isUsePickPoints: boolean
  name: string
}

const defaultValues: DeliveryMethodProps = {
  id: undefined,
  isAddressRequired: false,
  isDefault: false,
  isDeliveryRouteRequired: false,
  isUsePickPoints: false,
  name: '',
}

export type DeliveryMethod = RecordOf<DeliveryMethodProps>
export type DeliveryMethodsCollection = List<DeliveryMethod>

const makeDeliveryMethod: Record.Factory<DeliveryMethodProps> = Record(
  defaultValues,
  'DeliveryMethodRecord'
)

const mapper = ({ ...props }: DeliveryMethodProps): DeliveryMethodProps => ({
  ...props,
})

export const DeliveryMethod = (props: DeliveryMethodProps): DeliveryMethod =>
  makeDeliveryMethod(mapper(props))

export const DeliveryMethodsCollection = makeList(DeliveryMethod)
