import lazyPage from '../../hoc/lazyPage'
import { renderRoutes } from 'react-router-config'

const GoodsCatalog = props => renderRoutes(props.route.routes)

export default () => ({
  path: '/goods',
  component: GoodsCatalog,
  routes: [
    {
      path: '/goods',
      component: lazyPage(() =>
        import('packages/core/containers/GoodsCatalog/Brands')
      ),
      exact: true,
    },
    {
      path: '/goods/:brandName',
      component: lazyPage(() =>
        import('packages/core/containers/GoodsCatalog/Goods')
      ),
      exact: true,
    },
    {
      path: '/goods/:brandName/:number',
      component: lazyPage(() =>
        import('packages/core/containers/GoodsCatalog/GoodDetails')
      ),
      exact: true,
    },
  ],
})
