import { compose, getContext, withProps } from 'recompose'
import PropTypes from 'prop-types'
import { get, sumBy } from 'lodash'
// import { connect } from 'react-redux'
// import { createStructuredSelector } from 'reselect'

// import { withRecord, withHandler } from 'packages/core/hoc/withApp'
// import { makeSelectCartItemsAmount } from 'packages/core/redux/app/cart/selectors'

import TopToolbar from './TopToolbar'
// export default compose(
//   connect(mapStateToProps)
//   // withRecord('user'),
//   // withHandler('handleSearch')
// )(TopToolbar)

// import { compose, getContext } from 'recompose'
// import PropTypes from 'prop-types'

import { withRecord } from 'packages/core/hoc/withApp'

// const selectCartItemsAmount = makeSelectCartItemsAmount()
// const mapStateToProps = createStructuredSelector({
// cartAmount: selectCartItemsAmount,
// })
// import RightSection from './RightSection'

const withContext = getContext({
  cart: PropTypes.object,
  currentContract: PropTypes.object,
  user: PropTypes.object,
})

const withComponentProps = withProps(({ cart, currentContract }) => ({
  cartAmount: sumBy(cart.data, 'amount'),
  balance: get(currentContract, 'data.balance', 0),
}))

export default compose(
  withContext,
  withComponentProps,
  withRecord('company')
)(TopToolbar)

// export default compose(withContext, withRecord('company'))(RightSection)
