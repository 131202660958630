import {
  compose,
  getContext,
  pure,
  StateHandlerMap,
  withStateHandlers,
} from 'recompose'
import PropTypes from 'prop-types'

import ModalWindow from './ModalWindow'

export interface ToggleState {
  show: boolean
}

export interface ToggleHandler extends StateHandlerMap<ToggleState> {
  handleShow: () => ToggleState
  handleHide: () => ToggleState
}

const withToggle = withStateHandlers<ToggleState, ToggleHandler, ToggleState>(
  ({ show: initialShow = false }: ToggleState): ToggleState => {
    return {
      show: initialShow,
    }
  },
  {
    handleShow: () => () => ({
      show: true,
    }),
    handleHide: () => () => ({
      show: false,
    }),
  }
)

const withContext = getContext({
  user: PropTypes.object,
})

export default compose<
  ToggleState & ToggleHandler,
  ToggleState & ToggleHandler
>(
  withToggle,
  withContext,
  pure
)(ModalWindow)
