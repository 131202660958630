import React from 'react'
import FontAwesome from 'react-fontawesome'
import { Grid } from 'react-bootstrap'
import cn from 'classnames'
import { uniqueId } from 'lodash'

import Loader from 'packages/components/common/Loader'

import style from './style.module.scss'

const Section = ({
  id = null,
  title,
  icon,
  children,
  fluid = false,
  isFetching = false,
  className = null,
  bottomSpace = false,
  bottomSpaceLarge = false,
  bottomSpaceMedium = false,
  bottomSpaceSmall = false,
}) => (
  <section
    id={id || uniqueId('section-')}
    className={cn(style.section, className, {
      [style.sectionBottomSpace]: bottomSpace,
      [style.sectionBottomSpaceLarge]: bottomSpaceLarge,
      [style.sectionBottomSpaceMedium]: bottomSpaceMedium,
      [style.sectionBottomSpaceSmall]: bottomSpaceSmall,
    })}
  >
    <header className={style.sectionHeader}>
      {title && (
        <div className={style.sectionTitle}>
          <h1>{title}</h1>
        </div>
      )}
      <div className={style.sectionIcon}>
        <FontAwesome name={icon} />
      </div>
    </header>
    <Grid fluid={fluid}>{isFetching ? <Loader /> : children}</Grid>
  </section>
)

export default Section
