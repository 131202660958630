import React from 'react'
import { kebabCase } from 'lodash'

import { Widget, WidgetProps } from './Widget'
import Link from 'packages/components/common/Link'

type LinksBlockProps = {
  links: Array<{
    name: string
    url: string
  }>
} & WidgetProps

export const LinksBlock: React.FC<LinksBlockProps> = ({ links, ...props }) => (
  <Widget {...props}>
    <ul>
      {links.map(item => (
        <li key={kebabCase(item.url)}>
          <Link to={item.url}>{item.name}</Link>
        </li>
      ))}
    </ul>
  </Widget>
)
