import { ilcats } from 'packages/core/config'

const initialState = [
  {
    name: 'Главная',
    link: '/',
    exact: true,
  },
  {
    name: 'Каталоги',
    menu: [
      {
        name: 'Оригинальные каталоги подбора',
        link: `https://www.ilcats.ru/?clid=${ilcats.clientId}`,
      },
      {
        name: 'Запчасти для ТО',
        link: `https://www.ilcats.ru/maintenance&clid=${ilcats.clientId}`,
      },
    ],
  },
  // {
  //   name: 'Автосервис',
  //   link: '/service',
  // },
  {
    name: 'Полезное',
    link: '/about',
    menu: [
      {
        name: 'Контакты',
        link: '/contacts',
      },
      {
        name: 'Наши магазины',
        link: '/about',
      },

      // {
      //   name: '-Оптовым покупателям',
      // },
      // {
      //   name: '-Как оплатить',
      // },
      // {
      //   name: '-Доставка',
      // },
      // {
      //   name: '-Возврат товара',
      // },
      {
        name: 'Каталог товаров',
        link: '/goods',
      },
      {
        name: 'Статьи',
        link: '/blog',
      },
      {
        name: 'Обмен и возврат товара',
        link: '/obmen_vozvrat_tovara',
      },
      {
        name: 'Доставка',
        link: '/dostavka',
      },
      {
        name: 'Как оплатить заказ',
        link: '/kak_oplatit_zazkaz',
      },
      {
        name: 'Договор оферты',
        link: '/oferta',
      },
      {
        name: 'Пользовательское соглашение',
        link: '/policy/terms-of-use',
      },
      {
        name: 'Ограничения на использование информации',
        link: '/policy/restrictions-of-use',
      },
      {
        name: 'Согласие с обработкой персональных данных',
        link: '/policy/processing-personal-data',
      },
      {
        name: 'Политика конфиденциальности',
        link: '/policy/privacy-policy',
      },
    ],
  },
  // {
  //   name: 'Товары',
  //   link: '/goods',
  // },
  {
    name: 'Кабинет',
    link: '/cabinet',
    menu: [
      {
        name: 'Перейти в кабинет',
        link: '/cabinet',
      },
      // {
      //   name: 'Перейти в админпанель',
      //   link: '/admin',
      //   roles: 'admin',
      // },
      {
        name: 'Баланс',
        link: '/balance',
      },
      {
        name: 'Корзина',
        link: '/cart',
      },
      {
        name: 'Заказы',
        link: '/orders',
      },
      // {
      //   name: '-Скачать прайс-лист',
      // },
      {
        name: 'Гараж',
        link: '/garage',
      },
      // {
      //   name: 'Выход',
      //   link: '/logout',
      // },
    ],
  },
  {
    name: 'Контакты',
    link: '/contacts',
  },
]

export default initialState
