import React from 'react'
import FontAwesome from 'react-fontawesome'
import cn from 'classnames'
// import { Link } from 'react-router-dom'

import style from './style.module.scss'

const Widget = ({ className, icon, children, url }) => (
  <div className={cn(style.headerWidget, className)}>
    {/* <Link to={url}> */}
    <FontAwesome name={icon} className={style.icon} />
    <div className={style.body}>{children}</div>
    {/* </Link> */}
  </div>
)

export default Widget
