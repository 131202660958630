// import Good from './Good2'

// export { default as BlogPostRecord } from './BlogPost'
// export { default as BrandRecord } from './Brand'
// export { default as CartItemRecord } from './CartItem'
export { default as CompanyRecord } from './Company'
// export { default as ContractRecord } from './Contract'
// export { default as GoodRecord } from './Good'
// export { default as OfferRecord } from './Offer'
export { default as MenuRecord } from './Menu'
export { default as PaginatorRecord } from './Paginator'
// export { default as UserRecord } from './User'
// export { default as VendorRecord } from './Vendor'
