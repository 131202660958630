import { Record } from 'immutable'

// export const ReducerRecord = Record({
//   token: null,
//   refreshToken: null,
//   switchUser: null,
//   error: null,
// })

export class ReducerRecord extends Record({
  token: null,
  refreshToken: null,
  switchUser: null,
  // error: null,
}) {
  constructor({ ...values } = {}) {
    super(Object.assign({}, values, {}))
  }
}
