import { MODULE as PARENT } from '../constants'

export const KEY = 'auth'
export const MODULE = [...[PARENT], KEY]

// export const KEY = 'app/auth'
export const INITIALIZE = `${MODULE.join('/')}-initialize`
export const AUTHENTICATE = `${MODULE.join('/')}-authenticate`
export const LOGOUT = `${MODULE.join('/')}-logout`
export const CLEAR_TOKENS = `${MODULE.join('/')}-clear-tokens`
export const SET_TOKENS = `${MODULE.join('/')}-set-tokens`
export const SET_SWITCH_USER = `${MODULE.join('/')}-switch-user`

export const TOKEN = 'authToken'
export const REFRESH_TOKEN = 'refreshToken'
export const SWITCH_USER = '_switch_user'
