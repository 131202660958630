import React from 'react'
import { useFormikContext } from 'formik'
import FontAwesome from 'react-fontawesome'
import { keys, omit } from 'lodash'

import { Button, ButtonProps } from '../Button'

export type SubmitButtonProps = {
  disabledInvalid?: boolean
  icon?: string
  type?: string
  buttonComponent?: React.FC<ButtonProps>
} & ButtonProps

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children = null,
  disabled = false,
  disabledInvalid = false,
  icon,
  buttonComponent: BaseButton = Button,
  ...props
}) => {
  const formikContext = useFormikContext()
  const { isSubmitting, isValid, isValidating, submitForm } = formikContext

  const indicatorIcon = isSubmitting ? 'circle-o-notch' : icon

  return (
    <BaseButton
      disabled={
        isSubmitting ||
        (disabledInvalid && !isValid) ||
        isValidating ||
        disabled
      }
      {...(props.type !== 'submit' && { onClick: submitForm })}
      {...omit(props, keys(formikContext))}
    >
      {children}
      {indicatorIcon && (
        <>
          &nbsp;
          <FontAwesome name={indicatorIcon} spin={isSubmitting} fixedWidth />
        </>
      )}
    </BaseButton>
  )
}

export default SubmitButton
