import './style.scss'
import 'moment/locale/ru'
import './configure'

import { compose } from 'recompose'

import App from './App'
import withAutoScrollToTop from 'packages/core/hoc/withAutoScrollToTop'
import { withIntlSettings } from './i18n'

export default compose(withAutoScrollToTop, withIntlSettings)(App)
