import { FETCH_OFFICES, CLEAR_OFFICES } from './constants'
import { OfficesCollection } from 'packages/core/records'

export function fetchOffices() {
  return {
    type: FETCH_OFFICES,
    request: {
      url: '/offices',
    },
    meta: {
      resetOn: [CLEAR_OFFICES],
      getData: (state, action) => OfficesCollection(action.data),
    },
  }
}

export function clearOffices() {
  return {
    type: CLEAR_OFFICES,
  }
}
