import { MODULE as PARENT } from '../constants'

export const KEY = 'user'
export const MODULE = [...[PARENT], KEY]
export const FETCH_USER = `${MODULE.join('/')}`
export const CLEAR_USER = `${MODULE.join('/')}-clear`
export const UPDATE_USER = `${MODULE.join('/')}-update`
export const REGISTER_USER = `${MODULE.join('/')}-register`
export const LOGIN_USER = `${MODULE.join('/')}-login`
export const LOGOUT_USER = `${MODULE.join('/')}-logout`
export const APPLY_USER_ROLES = `${MODULE.join('/')}-apply-roles`
export const RESET_PASSWORD = `${MODULE.join('/')}-reset-password`
export const RESET_PASSWORD_REQUEST = `${MODULE.join(
  '/'
)}-reset-password-request`
