import { requestsReducer } from 'redux-saga-requests'

import { ReducerRecord } from './records'

import {
  SET_QUERY,
  /*SET_CURRENT, FETCH_GOODS,*/
  CLEAR_GOODS,
} from './constants'

const reducer = requestsReducer({
  // actionType: FETCH_GOODS,
  resetOn: [CLEAR_GOODS],
  getDefaultData: () => ReducerRecord(),
  getData: ({ data }, action) => data.set('entities', action.data),
  mutations: {
    [SET_QUERY]: {
      updateData: ({ data }, { payload }) => data.set('query', payload),
      local: true,
    },
    // [SET_CURRENT]: {
    //   updateData: ({ data }, { payload }) => data.set('current', payload),
    //   local: true,
    // },
  },
})

export default reducer
