import { EntityRecord, ListRecord } from '../../utils/CollectionRecord'

const PhoneRecord = EntityRecord({
  number: null,
  name: null,
  isMain: false,
  isMobile: false,
})

const PhonesList = ListRecord(PhoneRecord)

PhoneRecord.List = PhonesList

export default PhoneRecord
