import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'
import { isImmutable } from 'immutable'
import { find, toLower } from 'lodash'

import makeStateSelector from '../../utils/makeStateSelector'
import { MODULE, FETCH_GOODS } from './constants'
import { ReducerRecord } from './records'

const stateSelector = makeStateSelector(MODULE, ReducerRecord)

export const selectQuery = createSelector(
  stateSelector,
  ({ data }) => data.query
)

export const requestStateSelector = getQuery({ type: FETCH_GOODS })
export const querySelector = createSelector(
  requestStateSelector,
  ({ data, ...state }) => ({
    ...state,
    data: isImmutable(data) ? data.valueSeq().toJS() : [],
  })
)

export const selectSuggest = createSelector(
  querySelector,
  selectQuery,
  ({ data: goods }, query) =>
    find(goods, good => {
      return toLower(query) === toLower(good.name)
    }) || query
)
