import { branch, compose, getContext, renderComponent } from 'recompose'
import PropTypes from 'prop-types'
import { Redirect, useLocation } from 'react-router'
import React from 'react'
import { chain } from 'lodash'
// import { LoginDialog } from './LoginDialog'

const RedirectToLoginPage = () => {
  const location = useLocation()
  const currentLocation = chain(location)
    .pick('pathname', 'search')
    .filter()
    .join('?')
    .value()

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { referrer: currentLocation },
      }}
    />
  )
}

export const withAuthUser = compose(
  getContext({
    user: PropTypes.object.isRequired,
  }),
  branch(props => {
    return !props.user.loading && props.user.data.isGuest
  }, renderComponent(RedirectToLoginPage))
)

export * from './LoginDialog'
