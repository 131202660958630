import { List, Record, RecordOf } from 'immutable'
import moment, { MomentInput } from 'moment'
import { isEmpty, isString } from 'lodash'

import htmlParser from 'packages/core/utils/htmlParser'

import { makeList } from 'packages/core/records/CollectionFactory'
import { TagsCollection, TagProps } from './Tag'
import { CommentsCollection, CommentProps } from './Comment'

export type PostProps = {
  title: string
  slug: string
  postThumb?: string
  postImage?: string
  image?: string
  thumb?: string
  link: string
  categoryType: string
  content:
    | null
    | string
    | React.DetailedReactHTMLElement<{}, HTMLElement>
    | React.DetailedReactHTMLElement<{}, HTMLElement>[]
  shortContent:
    | null
    | string
    | React.DetailedReactHTMLElement<{}, HTMLElement>
    | React.DetailedReactHTMLElement<{}, HTMLElement>[]
  description:
    | null
    | string
    | React.DetailedReactHTMLElement<{}, HTMLElement>
    | React.DetailedReactHTMLElement<{}, HTMLElement>[]
  createdAt: MomentInput
  tags: TagProps[] | TagsCollection
  likesCount: number
  comments: CommentProps[] | CommentsCollection
}
export type Post = RecordOf<PostProps>
export type PostsCollection = List<Post>

const defaultValues: PostProps = {
  title: '',
  slug: '',
  image: undefined,
  thumb: undefined,
  link: '',
  categoryType: '',
  content: '',
  shortContent: '',
  description: '',
  createdAt: '',
  tags: TagsCollection(),
  likesCount: 0,
  comments: CommentsCollection(),
}

const mapper = ({
  createdAt,
  image,
  // mainImage,
  // slug,
  tags = [],
  comments = [],
  content,
  description,
  ...props
}: PostProps): PostProps => ({
  ...props,
  createdAt: moment(createdAt),
  image: props.postThumb,
  thumb: props.postImage,
  link: `/blog/${props.categoryType}/${props.slug}`,
  tags: TagsCollection(tags as TagProps[]),
  comments: CommentsCollection(comments as CommentProps[]),
  content: isEmpty(content) || !isString(content) ? null : htmlParser(content),
  description:
    isEmpty(description) || !isString(description)
      ? null
      : htmlParser(description),
})

const makePost: Record.Factory<PostProps> = Record(defaultValues, 'PostRecord')

export const Post = (props?: PostProps): Post =>
  makePost(props ? mapper(props) : undefined)
export const PostsCollection = makeList(Post)
