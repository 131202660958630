import { chain, replace } from 'lodash'

import { EntityRecord } from '../../utils/CollectionRecord'

const AddressRecord = EntityRecord(
  {
    country: null,
    region: null,
    city: null,
    street: null,
    building: null,
    additional: null,
    fullAddress: null,
  },
  ({ region, city, street, building }) => ({
    fullAddress: chain([region, city, street, building])
      .mapValues(value => replace(value, /\s+/g, '\u00A0'))
      .values()
      .filter()
      .join(', ')
      .value(),
  })
)

export default AddressRecord
