import { List } from 'immutable'
import moment from 'moment'
import { get, isEmpty, isEqual, pick, take } from 'lodash'

import { EntityRecord, ListRecord } from '../../utils/CollectionRecord'

const DayRecord = EntityRecord(
  {
    day: null,
    weekDay: null,
    shortWeekDay: null,
    timeFrom: null,
    timeTo: null,
    isHoliday: false,
  },
  ({ day, timeFrom, timeTo, ...rest }) => ({
    day,
    weekDay: moment.weekdays(day),
    shortWeekDay: moment.weekdaysShort(day),
    timeFrom,
    timeTo,
    isHoliday: isEmpty(timeFrom) && isEmpty(timeTo),
  })
)

const DaysRangeRecord = EntityRecord({
  weekDayFrom: null,
  shortWeekDayFrom: null,
  weekDayTo: null,
  shortWeekDayTo: null,
  timeFrom: null,
  timeTo: null,
  isHoliday: false,
})

const WeekRecord = (days = []) => {
  const week = take(days.concat(Array(7)), 7).map((day, idx) => ({
    day: idx + 1,
    timeFrom: get(day, 0, null),
    timeTo: get(day, 1, null),
  }))
  return ListRecord(DayRecord)(week)
}

const shortFormat = week => {
  return List().withMutations(short => {
    week.forEach(day => {
      const idx = short.findLastIndex(dayRange =>
        isEqual(
          pick(dayRange, 'timeFrom', 'timeTo'),
          pick(day, 'timeFrom', 'timeTo')
        )
      )
      short.update(
        idx < 0 ? short.count() : idx,
        DaysRangeRecord(day.toJS()),
        dayRange => {
          const changes = {
            [dayRange.shortWeekDayFrom
              ? 'shortWeekDayTo'
              : 'shortWeekDayFrom']: day.shortWeekDay,
            [dayRange.weekDayFrom ? 'weekDayTo' : 'weekDayFrom']: day.weekDay,
          }
          return dayRange.merge(changes)
        }
      )
    })
  })
}

const BusinessHoursRecord = EntityRecord(
  {
    full: WeekRecord(),
    short: List(),
  },
  ({ days }) => ({
    full: WeekRecord(days),
    short: shortFormat(WeekRecord(days)),
  })
)

export default BusinessHoursRecord
