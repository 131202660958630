import lazyPage from 'packages/core/hoc/lazyPage'

export default () => [
  {
    path: '/registration',
    component: lazyPage(() => import('packages/core/containers/Registration')),
    exact: true,
  },
  {
    path: '/resetting/reset/:token',
    component: lazyPage(() => import('packages/core/containers/ResetPassword')),
    exact: true,
  },
]
