import React from 'react'
import ConditionalWrap from 'conditional-wrap'

export type LayoutProps = React.PropsWithChildren<{
  contentComponent?: React.ReactNode
  headerComponent?: React.ComponentClass<any> | React.StatelessComponent<any>
  // headerComponent?: React.ReactNode
  footerComponent?: React.ComponentClass<any> | React.StatelessComponent<any>
}>

export const Layout: React.FC<LayoutProps> = ({
  children: Inner,
  contentComponent: Content,
  headerComponent: Header,
  footerComponent: Footer,
}) => {
  return (
    <>
      {Header && <Header />}
      <ConditionalWrap
        condition={Boolean(Content) && React.isValidElement(Content)}
        wrap={(children: typeof Inner) =>
          React.createElement(Content as any, {}, children)
        }
      >
        {Inner}
      </ConditionalWrap>
      {Footer && <Footer />}
    </>
  )
}

export default Layout
