import { createSelector } from 'reselect'
import { getQuery } from 'redux-saga-requests'

import { FETCH_CART } from './constants'

const selectCartRequest = getQuery({ type: FETCH_CART })

export const cartSelector = createSelector(
  selectCartRequest,
  ({ data: cart, ...state }) => {
    return {
      ...state,
      data: cart ? cart.sort((itemA, itemB) => itemB.id - itemA.id).toJS() : [],
    }
  }
)
