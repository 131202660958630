import { cloneElement, createElement } from 'react'
import { compose } from 'recompose'
import loadable from '@loadable/component'
import { withRouter } from 'react-router-dom'
import pMinDelay from 'p-min-delay'

import configurableComponent from 'packages/core/utils/configurableComponent'

// const AsyncPage = loadable(
//   (props: { page: string }) => import(`./${props.page}`),
//   {
//     fallback: createElement('div', null, 'Loading...'),
//   }
// )

const [withConfig, configure] = configurableComponent({
  delay: 0,
  pageLoader: undefined,
})

export { configure }

const loaded: { [key: string]: any } = {}

const lazyPage = (loader: any) =>
  withRouter(({ children, location, delay = 0, pageLoader, ...props }: any) => {
    const componentKey = location.pathname.toLowerCase()
    if (!!loaded[componentKey]) return cloneElement(loaded[componentKey], props)

    const Component = loadable<any>(
      () =>
        delay > 0 && !loaded[componentKey]
          ? pMinDelay(loader(), delay)
          : loader(),
      {
        fallback: pageLoader ? createElement(pageLoader) : undefined,
      }
    )
    // const Component = createElement(AsyncPage, {page: })
    // Component.load().then(() => {})

    return (loaded[componentKey] = createElement(Component, props, children))
  })

export default compose(withConfig, lazyPage)
