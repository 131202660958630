import { castArray, isArray, isEmpty, trimStart, memoize } from 'lodash'
import { imageURL } from 'packages/core/config'

const getImagineUrl = (
  image: string | Array<string> | undefined,
  filter: string
): typeof image =>
  isEmpty(image)
    ? undefined
    : isArray(image)
    ? (image.map((image: string) => getImagineUrl(image, filter)) as Array<
        string
      >)
    : (`${imageURL}/${filter}/${trimStart(image, '/')}` as string)

export default memoize(
  getImagineUrl,
  (image, filter) => castArray(image).join('') + filter
)
