import React from 'react'
import { Col, ColProps } from 'react-bootstrap'

import style from './style.module.scss'

export type WidgetProps = Omit<ColProps, 'title'> & {
  title: React.ReactNode
}

export const Widget: React.FC<WidgetProps> = ({
  title,
  children,
  ...props
}) => (
  <Col {...props}>
    <div className={style.footerWidget}>
      <h3>{title}</h3>
      {children}
    </div>
  </Col>
)
