import { createSelector } from 'reselect'
import { get } from 'lodash'

import { MODULE } from './constants'
import { ReducerRecord } from './records'

const stateSelector = state => get(state, MODULE, new ReducerRecord())
export const tokenSelector = createSelector(
  stateSelector,
  state => state.data.token
)

export const refreshTokenSelector = createSelector(
  stateSelector,
  state => state.data.refreshToken
)

export const switchUserSelector = createSelector(
  stateSelector,
  state => state.data.switchUser
)
