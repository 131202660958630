import React from 'react'
import { assign, get, fill, chunk } from 'lodash'

import Link from 'packages/components/common/Link'
import { Widget } from './Widget'
import { LinksBlock } from './LinksBlock'

import style from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { IOffice } from 'packages/core/records'
import { officesSelector } from 'packages/core/features/office/selectors'
import { ScheduleTable } from 'app/components/schedule_table'

const aboutLinks = [
  {
    name: 'Пользовательское соглашение',
    url: '/policy/terms-of-use',
  },
  {
    name: 'Использование информации',
    url: '/policy/restrictions-of-use',
  },
  {
    name: 'Обработка персональных данных',
    url: '/policy/processing-personal-data',
  },
  {
    name: 'Политика конфиденциальности',
    url: '/policy/privacy-policy',
  },
  {
    name: 'Каталог товаров',
    url: '/goods',
  },
]

const usefulBlock = {
  title: 'Это полезно',
  links: [
    {
      name: 'Договор оферты',
      url: '/oferta',
    },
    {
      name: 'Доставка',
      url: '/dostavka',
    },
    {
      name: 'Обмен и возврат товара',
      url: '/obmen_vozvrat_tovara',
    },
    {
      name: 'Сервис',
      url: '/service',
    },
  ],
}

const carsList = [
  {
    name: 'Toyota',
    url: '/goods/Toyota',
  },
  {
    name: 'Land Rover',
    url: '/goods/Land Rover',
  },
  {
    name: 'Isuzu',
    url: '/goods/Isuzu',
  },
  {
    name: 'Mazda',
    url: '/goods/Mazda',
  },
  {
    name: 'Renault',
    url: '/goods/Renault',
  },
  {
    name: 'Lexus',
    url: '/goods/Lexus',
  },
  {
    name: 'Honda',
    url: '/goods/Honda',
  },
  {
    name: 'Porsche',
    url: '/goods/Porsche',
  },
  {
    name: 'Suzuki',
    url: '/goods/Suzuki',
  },
  {
    name: 'Mitsubishi',
    url: '/goods/Mitsubishi',
  },
  {
    name: 'Lincoln',
    url: '/goods/Lincoln',
  },
  {
    name: 'Subaru',
    url: '/goods/Subaru',
  },
  {
    name: 'Nissan',
    url: '/goods/Nissan',
  },
  {
    name: 'Mercedes-Benz',
    url: '/goods/Mercedes-Benz',
  },
  {
    name: 'Daihatsu',
    url: '/goods/Daihatsu',
  },
]

const FooterWidgets: React.FC = () => {
  const { data: offices = [] } = useSelector<any, { data?: IOffice[] }>(
    officesSelector
  )

  const mainOffice = offices.find(office => office.isMain)

  return (
    <>
      <LinksBlock
        title={<FormattedMessage id="layout.footer.widgets.about.title" />}
        xsHidden
        sm={4}
        md={3}
        links={aboutLinks}
      />
      <LinksBlock xsHidden smHidden md={3} {...usefulBlock} />
      <Widget
        title={
          <FormattedMessage id="layout.footer.widgets.popular_brands.title" />
        }
        className={style.footerWidgetVehicles}
        xsHidden
        sm={4}
        md={3}
      >
        <table>
          <tbody>
            {chunk(carsList, 3).map((carsChunk, idx) => (
              <tr key={idx}>
                {assign(fill(new Array(3), null), carsChunk).map((car, idx) => (
                  <td key={idx}>
                    <Link to={get(car, 'url')}>{get(car, 'name')}</Link>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <Link to="/goods" className={style.linkSeeAll}>
          <FormattedMessage id="layout.footer.widgets.popular_brands.show_all_brans" />
        </Link>
      </Widget>
      <Widget
        title={
          <FormattedMessage id="layout.footer.widgets.working_time.title" />
        }
        xs={12}
        sm={4}
        md={3}
      >
        {mainOffice?.workSchedule && (
          <ScheduleTable schedule={mainOffice.workSchedule} />
        )}
      </Widget>
    </>
  )
}

export default FooterWidgets
